import _ from 'lodash'
import { map as awaityMap } from 'awaity/esm'

// constants
import { PROPERTY_VARIABLE_TYPES } from 'constants/filter'

// utils
import {
  getImagesProperties,
  getPropertyDisplayNameFromMetadata,
} from 'helpers/asset'
import { displayValue } from 'helpers/utils'
import log, { reportErrors } from 'helpers/log'
import { AssetProperties } from 'types/asset'
import { PropertiesMetadata } from 'types/common'
import { Timezone } from 'types/datetime'

import type { ImageType } from 'components/common/ImageGallery'

export const NO_IMAGES_CONTENT = 'No Data'

const addImageProcess = (
  src: string
): Promise<{
  width?: number
  height?: number
}> => {
  return new Promise(resolve => {
    const img = new Image()
    img.onload = () => {
      resolve({ width: img.width, height: img.height })
    }
    img.onerror = () => resolve({})
    img.src = src
  })
}

const getLabel = ({
  propertiesMetadataKeyByName,
  properties,
  timezone,
  propertyName,
}) => {
  if (!propertyName) return undefined

  const displayName = getPropertyDisplayNameFromMetadata({
    propertiesMetadataKeyByName,
    propertyName,
  })
  const propertyValue = displayValue(properties[propertyName], timezone)
  return `${displayName}: ${propertyValue}`
}

const getImageList = ({
  properties,
  title,
  subtitle,
  filteredImagesMetadata,
  propertiesMetadataKeyByName,
}) =>
  _.flatMap(filteredImagesMetadata, ({ name, type }) => {
    const property = getPropertyDisplayNameFromMetadata({
      propertiesMetadataKeyByName,
      propertyName: name,
    })
    const src = properties[name]

    const commonPayload = {
      title,
      subtitle,
      property,
    }

    if (type === PROPERTY_VARIABLE_TYPES.array) {
      if (_.isArray(src)) {
        return _.map(src, (imageSrc, idx) => ({
          ...commonPayload,
          imageSrc,
          title: title && `${title}-${idx}`,
          subtitle: subtitle && `${subtitle}-${idx}`,
        }))
      }
      log.error(
        '[Asset]Image metadata type is array, but the src is an empty array.'
      )
      return []
    }

    return {
      ...commonPayload,
      imageSrc: src,
    }
  })

const fetchImage = async ({
  imageSrc,
  ...restImageProperties
}: {
  imageSrc: string
  title?: string
  subtitle: string
}): Promise<ImageType> => {
  const { title, subtitle } = restImageProperties
  const { width, height } = await addImageProcess(imageSrc)
  if (!width || !height) {
    reportErrors(
      `Forbidden to access asset image ${title}-${subtitle}(${imageSrc})`
    )
  }

  return {
    ...restImageProperties,
    src: imageSrc,
    width: width || 4,
    height: height || 3,
  }
}

export type GetAssetImageItems = {
  properties?: AssetProperties
  propertiesMetadata?: PropertiesMetadata
  visibleProperties: string[]
  titleProperty?: string
  subtitleProperty?: string
  timezone?: Timezone
}

export const getAssetImageItems = async ({
  properties = {},
  propertiesMetadata = [],
  visibleProperties,
  titleProperty,
  subtitleProperty,
  timezone,
}: GetAssetImageItems): Promise<ImageType[]> => {
  const imagesMetadata = getImagesProperties({ propertiesMetadata })
  const filteredImagesMetadata = imagesMetadata.filter(({ name }) => {
    return _.includes(visibleProperties, name) && properties[name]
  })
  const propertiesMetadataKeyByName = _.keyBy(propertiesMetadata, 'name')

  const imgTitle = getLabel({
    propertiesMetadataKeyByName,
    properties,
    timezone,
    propertyName: titleProperty,
  })
  const imgSubtitle = getLabel({
    propertiesMetadataKeyByName,
    properties,
    timezone,
    propertyName: subtitleProperty,
  })

  const toBeFetchedImageList = getImageList({
    properties,
    title: imgTitle,
    subtitle: imgSubtitle,
    filteredImagesMetadata,
    propertiesMetadataKeyByName,
  })

  return awaityMap(toBeFetchedImageList, fetchImage)
}
