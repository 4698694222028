import { useEffect, ReactElement } from 'react'
import _ from 'lodash'
import { useAsync } from 'react-use'

// constants
import { WIDGET_TYPES } from 'constants/widget'
import { ASSET_PROFILE_MEDIA_TYPES } from 'constants/assets'

// components
import { Loading, ImageGallery } from 'components/common'
import { NoAvailableWidget } from 'components/widget'

import type { ImageGalleryType } from 'types/common'
import type { AssetMediaType } from 'types/asset'

import {
  NO_IMAGES_CONTENT,
  getAssetImageItems,
  GetAssetImageItems,
} from './utils'

import scss from './index.module.scss'

export type DisplayImagesGalleryProp = GetAssetImageItems & {
  type: ImageGalleryType
  mediaType?: AssetMediaType
  onWidgetLoad: () => void
  setImageCount: React.Dispatch<React.SetStateAction<number>>
}

const DisplayImagesGallery = ({
  properties,
  propertiesMetadata,
  visibleProperties,
  titleProperty,
  subtitleProperty,
  timezone,
  type,
  mediaType = ASSET_PROFILE_MEDIA_TYPES.WEB,
  onWidgetLoad,
  setImageCount,
}: DisplayImagesGalleryProp): ReactElement => {
  const { value: imageItems, loading } = useAsync(async () => {
    return getAssetImageItems({
      properties,
      propertiesMetadata,
      visibleProperties,
      titleProperty,
      subtitleProperty,
      timezone,
    })
  }, [properties, propertiesMetadata, visibleProperties])

  useEffect(() => {
    if (imageItems && imageItems.length > 0) {
      setImageCount(imageItems.length)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageItems?.length])

  if (loading) return <Loading />

  return !imageItems || _.isEmpty(imageItems) ? (
    <div className={scss.center}>
      <NoAvailableWidget
        widgetType={WIDGET_TYPES.image}
        content={NO_IMAGES_CONTENT}
      />
    </div>
  ) : (
    <ImageGallery
      images={imageItems}
      type={type}
      mediaType={mediaType}
      onLoad={onWidgetLoad}
    />
  )
}

export default DisplayImagesGallery
