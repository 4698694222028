import { ReactElement } from 'react'
import { ArrayFieldTemplateProps, getTemplate, getUiOptions } from '@rjsf/utils'

const ArrayFieldTemplate = (props: ArrayFieldTemplateProps): ReactElement => {
  const { idSchema, uiSchema, registry, required, schema, title } = props
  const uiOptions = getUiOptions(uiSchema)
  const ArrayFieldDescriptionTemplate =
    getTemplate<'ArrayFieldDescriptionTemplate'>(
      'ArrayFieldDescriptionTemplate',
      registry,
      uiOptions
    )

  const ArrayFieldTitleTemplate = getTemplate<'ArrayFieldTitleTemplate'>(
    'ArrayFieldTitleTemplate',
    registry,
    uiOptions
  )

  return (
    <div>
      <div className='p-0 m-0'>
        <div className='p-0 m-0'>
          <ArrayFieldTitleTemplate
            idSchema={idSchema}
            title={uiOptions.title || title}
            schema={schema}
            uiSchema={uiSchema}
            required={required}
            registry={registry}
          />
          <ArrayFieldDescriptionTemplate
            idSchema={idSchema}
            description={uiOptions.description || schema.description}
            schema={schema}
            uiSchema={uiSchema}
            registry={registry}
          />
        </div>
      </div>
    </div>
  )
}

export default ArrayFieldTemplate
