// libraries
import { useToggle } from 'react-use'
import { useMemo } from 'react'

// constants
import { APP_URLS } from 'app/MissionControlMethaneSolution/constants/common'

// utils
import { getRouteUrlWithValues } from 'helpers/utils'

import type { IconButtonProps } from 'components/common/IconButton'

export const getDetectionLink = ({
  id,
  fullUrl = true,
}: {
  id?: string
  fullUrl?: boolean
}) =>
  id
    ? getRouteUrlWithValues(
        [
          ...(fullUrl ? [window.location.origin, process.env.PUBLIC_URL] : []),
          APP_URLS.EMISSIONS,
        ].join(''),
        { id }
      )
    : ''

const useDetectionLink = (
  detectionId?: string
): { detectionLinkButton: IconButtonProps; detectionLink: string } => {
  const [isCopied, toggleIsCopied] = useToggle(false)

  const detectionLink = useMemo(
    () => getDetectionLink({ id: detectionId }),
    [detectionId]
  )

  const detectionLinkButton: IconButtonProps = useMemo(
    () =>
      isCopied
        ? {
            icon: 'AiFillCheckCircle',
            size: 18,
            label: 'Copied',
            key: detectionId,
          }
        : {
            icon: 'LinkIcon',
            onClick: () => {
              navigator.clipboard.writeText(detectionLink)

              toggleIsCopied(true)
              setTimeout(() => {
                toggleIsCopied(false)
              }, 1000)
            },
            size: 18,
            label: 'Copy link',
            key: detectionId,
          },
    [detectionId, isCopied, detectionLink, toggleIsCopied]
  )

  return { detectionLinkButton, detectionLink }
}

export default useDetectionLink
