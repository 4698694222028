// libraries
import _ from 'lodash'
import orxe from 'openrosa-xpath-evaluator'
import MergeXML from 'mergexml'

// constants
import {
  ISSUE_FORM_CONTROL_TYPES,
  ISSUE_FORM_SPEC_REF_PREFIX,
  XFORM_BODY_UPLOAD_MEDIA_TYPE,
} from 'constants/issue'
import { SPEC_PARAMETERS_TYPES } from 'constants/common'
import { PROPERTY_VARIABLE_FORMATS } from 'constants/filter'
import { FORM_QUESTION_TYPES } from 'constants/formBuilder'

// utils
import { switchcaseF, toLowerCase } from 'helpers/utils'
import log, { reportException } from 'helpers/log'

import type { ID, Payload, SpecParams, SpecParamsValue } from 'types/common'
import type {
  XFormBodyNode,
  XFormBodyGroupNode,
  XFormBodyControlNode,
  XForm,
  XFormResult,
  XFormModel,
  XFormBind,
  XFormItemset,
  XFormSpec,
  XFormSpecificationParameters,
} from 'types/issue'

const convertSelectToEnum = ({ items }: XFormBodyControlNode) => {
  const options = _.map(items, item => ({
    key: item.value,
    displayName: item.label,
  }))
  return {
    options,
    creatable: false,
    isClearable: false,
    type: SPEC_PARAMETERS_TYPES.enum,
  }
}

const isReadOnly = (readOnly: string): boolean => readOnly === 'true()'
// https://xlsform.org/en/#question-types
// note	Display a note on the screen, takes no input. Shorthand for type=text with readonly=true.
const isNoteType = isReadOnly

const getFormControlSpecificSpec = (
  field: XFormBodyControlNode,
  bind: XFormBind
) => {
  const { control, mediatype } = field
  const { readonly } = bind || {}

  return switchcaseF({
    [ISSUE_FORM_CONTROL_TYPES.INPUT]: () => ({
      ...(isNoteType(readonly) && { visible: false }),
      type: 'string',
      questionType: FORM_QUESTION_TYPES.TEXT_INPUT,
    }),
    [ISSUE_FORM_CONTROL_TYPES.SELECT]: () => ({
      ...convertSelectToEnum(field),
      isMulti: true,
      separator: ' ',
      questionType: FORM_QUESTION_TYPES.DROPDOWN,
    }),
    [ISSUE_FORM_CONTROL_TYPES.SELECT1]: () => ({
      ...convertSelectToEnum(field),
      questionType: FORM_QUESTION_TYPES.DROPDOWN,
    }),
    [ISSUE_FORM_CONTROL_TYPES.UPLOAD]: () =>
      mediatype ===
        XFORM_BODY_UPLOAD_MEDIA_TYPE[PROPERTY_VARIABLE_FORMATS.image] && {
        format: PROPERTY_VARIABLE_FORMATS.image,
        type: 'string',
        questionType: FORM_QUESTION_TYPES.IMAGE_UPLOADER,
      },
  })(_.noop)(control)
}

export const getSpecificationParametersFromXForm = (
  xForm: XForm
): XFormSpec[] => {
  const { body, binds } = xForm || {}
  const bindsKeyByNodeSet = _.keyBy(binds, 'nodeset')
  const groupNodesRelevant = _.reduce(
    body,
    (acc, field) => {
      const { ref, childNodeIds } = field as XFormBodyGroupNode
      const { relevant } = bindsKeyByNodeSet[ref] || {}
      if (!childNodeIds || !relevant) return acc
      return {
        ...acc,
        ..._.zipObject(
          childNodeIds,
          _.fill(Array(childNodeIds.length), relevant)
        ),
      }
    },
    {} as { [key: ID]: string }
  )

  return _(body)
    .map((field: XFormBodyNode) => {
      const { id, label, hint, ref, itemset, childNodeIds } =
        field as XFormBodyGroupNode & XFormBodyControlNode
      if (!ref || ['end of form'].includes(toLowerCase(label)) || childNodeIds)
        return undefined

      const xFormBind = bindsKeyByNodeSet[ref] || {}
      const specificSpec = getFormControlSpecificSpec(
        field as XFormBodyControlNode,
        xFormBind
      )
      if (!specificSpec) return undefined

      const { relevant, type, required, readonly } = xFormBind
      const groupRelevant = groupNodesRelevant[id]
      const commonSpec = {
        visible: true,
        displayName: label,
        formFieldRef: ref,
        ...(groupRelevant && { groupRelevant }),
        ...(relevant && { relevant }),
        ...(type && { format: type }),
        ...(required && { necessity: isReadOnly(required) }),
        ...(readonly && { disabled: isReadOnly(required) }),
        ...(hint && { hint }),
        ...(itemset && { itemset }),
      }
      return {
        ...commonSpec,
        ...specificSpec,
        id: ref.replace(ISSUE_FORM_SPEC_REF_PREFIX, ''),
      }
    })
    .compact()
    .value()
}

const { evaluate: xFormEvaluate } = orxe()

const setFormValue = (
  xpathExpression: string,
  value: SpecParamsValue,
  formModelDom: Document
) => {
  const xpathResult = xFormEvaluate(
    xpathExpression,
    formModelDom,
    undefined,
    XPathResult.FIRST_ORDERED_NODE_TYPE
  )

  if (!xpathResult?.singleNodeValue) {
    log.error(
      `[updateIssueFormResultsInDom]${JSON.stringify(
        xpathExpression
      )} not found`
    )
    return
  }

  try {
    xpathResult.singleNodeValue.innerHTML = _.escape(value as string)
  } catch (e) {
    reportException(
      `Failed to set issue form value(${value}) to ${xpathExpression}. ${
        (e as Error).message
      }`
    )
  }
}

export const updateIssueFormResultsInDom = ({
  formModelDom,
  specificationParameters,
  payload,
}: {
  formModelDom: Document
  payload: SpecParams
  specificationParameters: XFormSpecificationParameters
}): void => {
  _.forEach(payload, (value, key) => {
    if (!specificationParameters[key]) return

    const newValue = _.isArray(value) ? value.join(' ') : value

    const xpathExpression = specificationParameters[key].formFieldRef as string
    setFormValue(`/${xpathExpression}`, newValue, formModelDom)
  })
}

export const parseXMLString = (
  domParser: DOMParser,
  formResultXml: string
): Document => domParser.parseFromString(formResultXml, 'application/xml')

const INSTANCE = /instance\(\s*(["'])((?:(?!\1)[A-z0-9.\-_]+))\1\s*\)/g

const getValidXPathExpression = (expression: string) => {
  return expression
    .replace(INSTANCE, (_match, _quote, id) => `/model/instance[@id="${id}"]`)
    .replaceAll('/data/', '//data/')
}

export const getXpathResult = (
  expression: string,
  xFormDom: Document,
  resultType: number
): XPathResult => {
  return xFormEvaluate(
    getValidXPathExpression(expression),
    xFormDom,
    undefined,
    resultType
  )
}

export const getXpathResultBoolean = (
  expression: string,
  xFormDom: Document
): {
  booleanValue: boolean
} => getXpathResult(expression, xFormDom, XPathResult.BOOLEAN_TYPE)

const getOptionsFromFormSpec = (
  itemset: XFormItemset,
  xFormModelDom: Document
) => {
  const { iterateNext } = getXpathResult(
    itemset.nodeset,
    xFormModelDom,
    XPathResult.ORDERED_NODE_SNAPSHOT_TYPE
  )
  let node = iterateNext()
  const options = []
  while (node) {
    const { labelRef, valueRef } = itemset
    const nodeObj = _.reduce(
      node.childNodes,
      (result, childnode) => {
        const { nodeName, innerHTML } = childnode as HTMLElement
        return { ...result, [nodeName]: innerHTML }
      },
      {} as Payload
    )
    options.push({
      displayName: nodeObj[labelRef],
      key: nodeObj[valueRef],
    })

    node = iterateNext()
  }

  return options
}

const shouldDisplaySpec = ({
  relevant,
  groupRelevant,
  visible,
  xFormModelDom,
}: {
  visible?: boolean
  relevant?: string
  groupRelevant?: string
  xFormModelDom: Document
}) => {
  if (!relevant && !groupRelevant) return visible

  if (
    groupRelevant &&
    !getXpathResultBoolean(groupRelevant, xFormModelDom).booleanValue
  ) {
    return false
  }

  return relevant
    ? getXpathResultBoolean(relevant, xFormModelDom).booleanValue
    : true
}

export const getUpdatedSpecificationParameters = ({
  xFormModelDom,
  specificationParameters,
}: {
  xFormModelDom: Document
  specificationParameters: XFormSpecificationParameters
}): XFormSpecificationParameters => {
  if (!xFormModelDom) return specificationParameters

  return _.reduce(
    specificationParameters,
    (acc, cur, key) => {
      const { relevant, groupRelevant, itemset, visible } = cur
      const newSpecPayload = {}
      if (itemset?.nodeset) {
        const options = getOptionsFromFormSpec(itemset, xFormModelDom)
        _.set(newSpecPayload, 'options', options)
      }

      const isVisible = shouldDisplaySpec({
        relevant,
        groupRelevant,
        visible,
        xFormModelDom,
      })
      _.set(newSpecPayload, 'visible', isVisible)

      return { ...acc, [key]: { ...cur, ...newSpecPayload } }
    },
    specificationParameters
  )
}

export const getModelDataInstance = (model: Document): Node | null =>
  model.querySelector('instance > *')

export const updateFormModelDataResult = (
  formModel: XFormModel,
  formResult: XFormResult
): Document => {
  const domParser = new DOMParser()
  const model = parseXMLString(
    domParser,
    _.replace(formModel, /\s(xmlns=("|')[^\s>]+("|'))/g, ' data-$1')
  )
  if (!formResult) return model

  let modelInstanceChildEl = getModelDataInstance(model)
  if (modelInstanceChildEl) {
    const merger = new MergeXML()
    const modelInstanceChildStr = new XMLSerializer().serializeToString(
      modelInstanceChildEl
    )
    merger.AddSource(modelInstanceChildStr)
    merger.AddSource(formResult)
    const { error } = merger
    if (merger.Get(1)) {
      // Remove the primary instance childnode from the original model
      model.querySelector('instance')?.removeChild(modelInstanceChildEl)
      // adopt the merged instance childnode
      const mergeResultDoc = parseXMLString(domParser, merger.Get(1))

      modelInstanceChildEl = model.adoptNode(mergeResultDoc.documentElement)
      // append the adopted node to the primary instance
      const modelInstanceEl = model.querySelector('instance')
      modelInstanceEl?.appendChild(modelInstanceChildEl)
    } else {
      reportException(
        `Merge issue form result to form model failed.${error?.text}`
      )
    }
  }

  return model
}
