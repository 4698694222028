import { useEffect, ReactElement } from 'react'
import _ from 'lodash'
import { map as awaityMap } from 'awaity/esm'
import { useAsync } from 'react-use'

// constants
import { WIDGET_TYPES } from 'constants/widget'

// components
import { Loading, ImageGallery } from 'components/common'
import { NoAvailableWidget } from 'components/widget'
import { Asset, AssetRelationshipMany } from 'types/asset'

import type { Images } from 'components/common/ImageGallery'
import type { DisplayImagesGalleryProp } from './DisplayImagesGallery'

import { NO_IMAGES_CONTENT, getAssetImageItems } from './utils'

import scss from './index.module.scss'

type DisplayImagesFromRelatedAssetsProp = Omit<
  DisplayImagesGalleryProp,
  'properties' | 'propertiesMetadata'
> & {
  value?: Asset & { related: AssetRelationshipMany }
}

const DisplayImagesFromRelatedAssets = ({
  value,
  visibleProperties,
  titleProperty,
  subtitleProperty,
  type,
  mediaType,
  onWidgetLoad,
  setImageCount,
}: DisplayImagesFromRelatedAssetsProp): ReactElement => {
  const { assets } = value?.related || {}
  const { properties: propertiesMetadata = [] } = value?.assetProfile || {}

  const { value: imageItems = [], loading } = useAsync(async () => {
    const response = await awaityMap(assets, (asset: Asset) =>
      getAssetImageItems({
        ...asset,
        propertiesMetadata,
        visibleProperties,
        titleProperty,
        subtitleProperty,
      })
    )
    return _.flatten(response)
  }, [assets, propertiesMetadata, visibleProperties])

  useEffect(() => {
    if (imageItems?.length > 0) {
      setImageCount(imageItems.length)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageItems?.length])

  if (loading) return <Loading />

  return _.isEmpty(imageItems) ? (
    <div className={scss.center}>
      <NoAvailableWidget
        widgetType={WIDGET_TYPES.image}
        content={NO_IMAGES_CONTENT}
      />
    </div>
  ) : (
    <ImageGallery
      images={imageItems as Images}
      type={type}
      mediaType={mediaType}
      onLoad={onWidgetLoad}
    />
  )
}

export default DisplayImagesFromRelatedAssets
