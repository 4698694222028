import { useMemo, ReactElement, forwardRef } from 'react'
import _ from 'lodash'

// components
import { StyledField } from 'components/common/Form'
import { MultiSelect } from 'components/common/'

import type { Options, Option, InputValue, Value } from 'types/common'
import type { MultiSelectProp } from 'components/common/MultiSelect'
import type { FieldWrapperProps } from 'components/common/Form/FieldWrapper'

import { useFieldError } from 'components/common/Form/FieldError'

export const getSelectedValue = (
  inputValue: InputValue<Value>
): Value | Value[] => {
  if (!_.isObject(inputValue)) return inputValue
  if (!_.isArray(inputValue)) return inputValue.value

  return _.map(inputValue, v =>
    _.isObject(v) ? (v as Option<Value>).value : v
  )
}

export type FormSelectProps = {
  options: Options
  input: {
    name: string
    value: InputValue
    onChange: (v: InputValue) => void
    onBlur: (v: InputValue) => void
    onFocus: (v: InputValue) => void
  }
  useOptionValueOnly: boolean
  groupOptionStyle: boolean
  onCreatableOptionsChange: () => void
  convertStringType: string
  areGroupedOptions?: boolean
} & Pick<
  FieldWrapperProps,
  | 'label'
  | 'description'
  | 'style'
  | 'labelClassName'
  | 'labelContainerClassName',
  | 'containerClassName'
  | 'preserveSpaceForError'
  | 'ignoreError'
  | 'onChecked'
  | 'checkable'
  | 'required'
> &
  MultiSelectProp

const FormSelect = forwardRef(
  (
    {
      input,
      label,
      required = false,
      description,
      groupOptionStyle = false,
      style,
      checkable,
      onChecked,
      labelClassName = 'form-label',
      labelContainerClassName,
      isMulti = false,
      useOptionValueOnly = true,
      ignoreError = false,
      areGroupedOptions = false,
      containerClassName = '',
      preserveSpaceForError,
      ...rest
    }: FormSelectProps,
    ref
  ): ReactElement => {
    const { value: inputValue } = input

    const { hasError } = useFieldError(input.name)
    const selectedValue = useMemo(
      () => getSelectedValue(inputValue),
      [inputValue]
    )

    const sharedProps = {
      ...rest,
      ...input,
      ...(areGroupedOptions &&
        _.isObject(inputValue) &&
        !_.isArray(inputValue) &&
        inputValue),
      value: selectedValue,
      useOptionValueOnly,
      isMulti,
      hasError,
      ref,
    }

    return (
      <StyledField
        name={input.name}
        groupOptionStyle={groupOptionStyle}
        label={label}
        required={required}
        description={description}
        style={style}
        checkable={checkable}
        onChecked={onChecked}
        labelClassName={labelClassName}
        labelContainerClassName={labelContainerClassName}
        ignoreError={ignoreError}
        containerClassName={containerClassName}
        preserveSpaceForError={preserveSpaceForError}
      >
        <MultiSelect {...sharedProps} />
      </StyledField>
    )
  }
)

export default FormSelect
