// libraries
import { ElementType, ReactElement, useCallback, useMemo } from 'react'
import { Form, Field } from 'react-final-form'
import _ from 'lodash'
import { ValidationErrors } from 'final-form'
import { useRecoilValue } from 'recoil'

// utils
import { validateKeyWithLimit, validateRequiredKey } from 'helpers/validators'
import { allAssetProfileOptionsState } from 'recoilStore/assetsStore'

// constants
import { ENTITY_NAME_CHARACTER_LIMIT } from 'constants/common'

// components
import { ModalFooter } from 'components/common'
import * as FormComponents from 'components/common/Form'
import { useAuthStateValue } from 'contexts'

// types
import type { Payload } from 'types/common'
import type { Entity } from 'types/entity'
import type { FormSelectProps } from 'components/common/Form/FormSelect'
import type { TextareaProps } from 'components/common/Form/Textarea'
import type { InputProps } from 'components/common/Form/Input'

// styles
import scss from './index.module.scss'

type Values = {
  name: string
  description: string
  owner: {
    group: string
  }
}

type SpecProps = {
  componentName?: string
  isVisible?: boolean
  name?: string
  'data-testid'?: string
  testId?: string
  maxLength?: number
}

type FormSpecType = {
  name: Partial<InputProps> & SpecProps
  description: Partial<TextareaProps> & SpecProps
  group: Partial<FormSelectProps> & SpecProps
  subjectAssetProfile: Partial<FormSelectProps> & SpecProps
}

const FORM_SELECT_COMMON_SPEC = {
  componentName: 'FormSelect',
  required: false,
  useOptionValueOnly: true,
  labelClassName: scss.label,
  withBorder: true,
}

const CreateEntityForm = ({
  onSubmit,
  onCancel,
  fields,
  image: Image,
}: {
  onSubmit: (payload: Payload) => Promise<void>
  onCancel: (v: boolean | Entity) => void
  fields: string[]
  image?: ElementType
}): ReactElement => {
  const {
    currentUser: { group: currentUserGroup },
    userGroupsOptions,
  } = useAuthStateValue()

  const assetProfileOptions = useRecoilValue(allAssetProfileOptionsState)

  const FORM_INPUT_SPECS: FormSpecType = {
    name: {
      label: 'Name',
      placeholder: 'Name',
      required: true,
      autoFocus: true,
      'data-testid': 'create-entity-name',
      maxLength: ENTITY_NAME_CHARACTER_LIMIT,
    },
    description: {
      componentName: 'Textarea',
      label: 'Description',
      placeholder: 'Optional description',
      required: false,
      className: 'form-control',
      testId: 'create-entity-desc',
    },
    group: {
      ...FORM_SELECT_COMMON_SPEC,
      label: 'Group',
      required: false,
      options: userGroupsOptions,
      isVisible: userGroupsOptions.length > 1,
      name: 'owner.group',
    },
    subjectAssetProfile: {
      ...FORM_SELECT_COMMON_SPEC,
      label: 'Asset Profile',
      required: true,
      options: assetProfileOptions,
      name: 'subjectAssetProfile',
      isClearable: false,
    },
  }

  const validateForm = (values: Values) => {
    const errors = {} as ValidationErrors
    _.forEach(FORM_INPUT_SPECS, (spec, key) => {
      if (_.includes(fields, key) && spec.required) {
        validateRequiredKey(key, spec.label)(values, errors)

        if (spec.maxLength) {
          validateKeyWithLimit(key, spec.label, spec.maxLength)(values, errors)
        }
      }
    })
    return errors
  }

  const initialValues = useMemo(() => {
    return { owner: { group: currentUserGroup } }
  }, [currentUserGroup])

  const handleOnFormSubmit = useCallback(
    (payload: Payload) => {
      return onSubmit(
        _.omitBy(
          {
            ...(userGroupsOptions.length > 1 && {
              owner: { group: currentUserGroup },
            }),
            ...payload,
          },
          _.isNil
        )
      )
    },
    [onSubmit, userGroupsOptions, currentUserGroup]
  )

  const renderEntityInformation = ({
    errors,
    handleSubmit,
    submitting,
    pristine,
    formFields,
  }: {
    errors: ValidationErrors
    handleSubmit: (v: Values) => void
    submitting: boolean
    pristine: boolean
    loading: boolean
    formFields: string[]
  }) => {
    return (
      <div className={scss.formWrapper}>
        <form className={scss.form} onSubmit={handleSubmit}>
          <div className={`${scss.content}  mt-4 mb-4`}>
            {Image && (
              <div className='text-center mv-4'>
                <Image />
                <hr />
              </div>
            )}
            {_(formFields)
              .reject({ isVisible: false })
              .map(formField => {
                const { componentName, name } =
                  FORM_INPUT_SPECS[formField as keyof FormSpecType]
                return (
                  <div className='mb-3' key={formField}>
                    <Field
                      {...FORM_INPUT_SPECS[formField as keyof FormSpecType]}
                      name={name || formField}
                      component={_.get(
                        FormComponents,
                        componentName as string,
                        FormComponents.Input
                      )}
                    />
                  </div>
                )
              })
              .value()}
          </div>
          <ModalFooter
            onCancel={onCancel}
            submitting={submitting}
            pristine={pristine}
            submitContent='Create'
            disabled={submitting || pristine || !_.isEmpty(errors)}
          />
        </form>
      </div>
    )
  }

  return (
    <Form
      initialValues={initialValues}
      validate={validateForm}
      onSubmit={handleOnFormSubmit}
      render={renderEntityInformation}
      formFields={fields}
    />
  )
}

export default CreateEntityForm
