// libraries
import React from 'react'
import PropTypes from 'prop-types'

// utils
import { useAssetWidgetConfigs } from 'components/assets/assetsProfile/hooks'
import { DEFAULT_ASSET_WIDGET_DIVIDER_STYLE } from 'constants/assets'

// components
import { ColourPickerSimple, RangeSlider } from 'components/common'

const AssetWidgetDividerPanel = ({ widget }) => {
  const {
    settings: {
      colour = DEFAULT_ASSET_WIDGET_DIVIDER_STYLE.colour,
      height = DEFAULT_ASSET_WIDGET_DIVIDER_STYLE.height,
      opacity = DEFAULT_ASSET_WIDGET_DIVIDER_STYLE.opacity,
    },
  } = widget

  const { updateWidgetConfigsSettings } = useAssetWidgetConfigs(widget)

  return (
    <>
      <div className='groupOption'>
        <RangeSlider
          title='Height'
          value={height}
          range={[0, 10]}
          onChange={val => updateWidgetConfigsSettings({ height: val })}
        />
      </div>
      <div className='groupOption'>
        <RangeSlider
          title='Opacity'
          value={opacity}
          step={0.01}
          range={[0, 1]}
          onChange={val => updateWidgetConfigsSettings({ opacity: val })}
        />
      </div>
      <div className='groupOption'>
        <div className='groupOptionTitle'>Select colour</div>
        <div className='groupOptionContent'>
          <ColourPickerSimple
            colour={colour}
            onChange={val => updateWidgetConfigsSettings({ colour: val })}
          />
        </div>
      </div>
    </>
  )
}

AssetWidgetDividerPanel.propTypes = {
  widget: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    settings: PropTypes.shape({
      colour: PropTypes.arrayOf(PropTypes.number),
      height: PropTypes.number,
      opacity: PropTypes.number,
    }).isRequired,
  }).isRequired,
}

export default AssetWidgetDividerPanel
