import { useCallback, useState } from 'react'
import _ from 'lodash'
import { useUpdateEffect } from 'react-use'

// constants
import * as ISSUE_CONSTANTS from 'constants/issue'

// utils
import { getIssueGeojsonRow, updateIssueItem } from 'helpers/issue'
import { useCurrentUser } from 'hooks'
import { mutateIssueFactory } from 'services/api/issue'

const { ISSUE_ACTION_TYPES } = ISSUE_CONSTANTS

const useIssue = ({ issue, onChange }) => {
  const [extendedIssue, setExtendedIssue] = useState(issue)
  const { issueAssigneesOptions, issueSeverityOptions } = useCurrentUser()

  useUpdateEffect(() => {
    if (!_.isEqual(issue, extendedIssue)) {
      setExtendedIssue(issue)
    }
  }, [issue])

  const onExtendedIssueChange = useCallback(
    (properties, otherPayload = {}) => {
      const newExtendedIssue = {
        ...extendedIssue,
        properties,
        ...otherPayload,
      }
      setExtendedIssue(newExtendedIssue)
      onChange(newExtendedIssue)
    },
    [extendedIssue, onChange]
  )

  const onIssueResponseSuccess = useCallback(
    response => {
      if (_.isEmpty(response)) return

      const { properties, ...otherPayload } = getIssueGeojsonRow(
        issueAssigneesOptions,
        issueSeverityOptions
      )(response)

      onExtendedIssueChange(properties, otherPayload)
    },
    [issueAssigneesOptions, issueSeverityOptions, onExtendedIssueChange]
  )

  const updateIssueAction = useCallback(
    ({ actionType, status, updateEntity, inputName }) =>
      async variables => {
        const updateFn = mutateIssueFactory(actionType, inputName)
        return updateIssueItem({
          status,
          updateFn,
          variables: { ...variables, issueId: issue.id },
          updateEntity,
          onSuccess: onIssueResponseSuccess,
        })
      },
    [issue?.id, onIssueResponseSuccess]
  )

  const updateIssue = updateIssueAction({
    actionType: ISSUE_ACTION_TYPES.updateIssue,
    status: 'Update',
  })

  const commentIssue = updateIssueAction({
    actionType: ISSUE_ACTION_TYPES.commentIssue,
    status: 'Comment',
  })

  const modifyIssueComment = updateIssueAction({
    actionType: ISSUE_ACTION_TYPES.modifyIssueComment,
    status: 'Update',
    updateEntity: 'issue comment',
  })

  const deleteIssueComment = updateIssueAction({
    actionType: ISSUE_ACTION_TYPES.deleteIssueComment,
    status: 'Delete',
    updateEntity: 'issue comment',
  })

  const closeIssue = updateIssueAction({
    actionType: ISSUE_ACTION_TYPES.closeIssue,
    status: 'Close',
  })

  const reopenIssue = updateIssueAction({
    actionType: ISSUE_ACTION_TYPES.reopenIssue,
    status: 'Reopen',
  })

  const assignIssueToUser = updateIssueAction({
    actionType: ISSUE_ACTION_TYPES.assignIssueToUser,
    status: 'Assign',
  })

  const acknowledgeAssignment = updateIssueAction({
    actionType: ISSUE_ACTION_TYPES.acknowledgeAssignment,
    status: 'Acknowledge',
    inputName: 'AcknowledgeAssignment!',
  })

  return {
    extendedIssue,
    actions: {
      updateIssue,
      commentIssue,
      modifyIssueComment,
      deleteIssueComment,
      closeIssue,
      reopenIssue,
      assignIssueToUser,
      acknowledgeAssignment,
    },
  }
}

export default useIssue
