import { CompositeLayer } from '@deck.gl/core/typed'
import { ScatterplotLayer, TextLayer } from '@deck.gl/layers/typed'
import Supercluster from 'supercluster'
import _ from 'lodash'
import Color from 'color'

// utils
import { isValueInRange } from 'helpers/utils'

// constants
import { DEFAULT_IDENTITY_PROPERTY } from 'constants/common'

const getLabelText = size => {
  if (size === 0) {
    return ''
  }
  if (size < 10) {
    return `${size}`
  }
  if (size < 100) {
    return `${Math.floor(size / 10)}0+`
  }
  return '100+'
}

const getPointRadius = size => Math.min(100, size) / 100 + 1

const getTextSize = (size, sizeScale) => (sizeScale * getPointRadius(size)) / 2

export default class ScatterplotClusterLayer extends CompositeLayer {
  shouldUpdateState({ changeFlags }) {
    return changeFlags.somethingChanged
  }

  updateState({ props, oldProps, changeFlags }) {
    const {
      data,
      sizeScale,
      filterEnabled,
      filterRange,
      getFilterValue,
      nonClusterLayerClass,
    } = props
    const rebuildIndex =
      changeFlags.dataChanged ||
      sizeScale !== oldProps.sizeScale ||
      filterEnabled !== oldProps.filterEnabled ||
      !_.isEqual(filterRange, oldProps.filterRange)

    if (rebuildIndex) {
      const index = new Supercluster({
        maxZoom: 22,
        radius: sizeScale * Math.sqrt(2),
      })

      const filteredData = filterEnabled
        ? data.filter(f => {
            return isValueInRange(getFilterValue(f), filterRange, true)
          })
        : data

      const useNonClusterLayer = !!nonClusterLayerClass

      index.load(
        filteredData.map(d => ({
          ...d,
          geometry: { ...d.geometry, coordinates: props.getPosition(d) },
          ...(!useNonClusterLayer && { properties: d }),
        }))
      )
      this.setState({ index })
    }

    const z = Math.floor(this.context.viewport.zoom)
    if (rebuildIndex || z !== this.state.z) {
      this.setState({
        data: this.state.index.getClusters([-180, -85, 180, 85], z),
        z,
      })
    }
  }

  getPickingInfo({ info, mode }) {
    const pickedObject = info.object?.properties
    const id = this.props.identityProperty || DEFAULT_IDENTITY_PROPERTY
    const displayKey = this.props.profileTitle || id

    info.object = pickedObject

    if (pickedObject) {
      info.cluster = !!pickedObject.cluster
      if (mode === 'hover') return info

      info.object = pickedObject.cluster
        ? this.state.index
            .getLeaves(pickedObject.cluster_id, Infinity)
            .map(f => {
              // src/components/map/hooks/useMapClickedFeatures.ts
              // getClusteringPointsDisplayedValues
              const { profile } = f.properties
              return {
                key: profile
                  ? _.get(f, 'properties.id')
                  : _.get(f, ['properties', 'properties', id]),
                name: profile ? 'Name' : displayKey,
                value: profile
                  ? _.get(f, 'properties.displayName')
                  : _.get(f, ['properties', 'properties', displayKey]),
              }
            })
        : pickedObject

      info.clusterId = pickedObject.cluster_id
      info.clusterPointCount = pickedObject.point_count
    }

    return info
  }

  renderLayers() {
    const { data } = this.state

    const {
      id,
      fillColour,
      opacity,
      visible,
      sizeScale = 40,
      nonClusterLayerClass: NonClusterLayerClass,
      nonClusterLayerProps,
    } = this.props

    const commonProps = {
      data,
      opacity,
      visible,
      getPosition: d => d.geometry.coordinates,
    }

    const useNonClusterLayer = !!NonClusterLayerClass
    const nonClusterData = _.reject(data, 'properties.cluster')

    const getSize = d => (d.properties.cluster ? d.properties.point_count : 1)

    return [
      ...(useNonClusterLayer
        ? [
            new NonClusterLayerClass({
              id: `${id}-non-cluster-layer`,
              ...nonClusterLayerProps,
              data: nonClusterData,
            }),
          ]
        : []),
      new ScatterplotLayer(
        this.getSubLayerProps({
          id: `${id}-cluster-point`,
          ...commonProps,
          getFillColor: fillColour,
          stroked: true,
          getLineColor: [fillColour[0], fillColour[1], fillColour[2], 100],
          getLineWidth: 6,
          radiusScale: sizeScale / 2,
          radiusUnits: 'pixels',
          lineWidthUnits: 'pixels',
          getRadius: d =>
            d.properties.cluster ? getPointRadius(getSize(d)) : 0,
        })
      ),
      new TextLayer(
        this.getSubLayerProps({
          id: `${id}-cluster-label`,
          ...commonProps,
          fontFamily: 'Open Sans',
          fontWeight: 400,
          getText: d => getLabelText(getSize(d)),
          getColor: Color(fillColour).isLight() ? [0, 0, 0] : [255, 255, 255],
          getSize: d =>
            d.properties.cluster ? getTextSize(getSize(d), sizeScale) : 0,
          getAngle: 0,
          getTextAnchor: 'middle',
          getAlignmentBaseline: 'center',
          autoHighlight: false,
          pickable: false,
        })
      ),
    ]
  }
}

ScatterplotClusterLayer.layerName = 'ScatterplotClusterLayer'

ScatterplotClusterLayer.defaultProps = {
  getPosition: { type: 'accessor', value: d => d.geometry.coordinates },
  sizeScale: 40,
}
