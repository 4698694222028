import { ReactElement, useState } from 'react'

import { IconButton } from 'components/common'

import scss from './index.module.scss'

const ExternalLinkPopOver = ({
  externalUrl,
}: {
  externalUrl: string
}): ReactElement => {
  const [showImg, setShowImg] = useState(false)
  const { hostname, protocol } = new URL(externalUrl as string)
  const iconSize = 20

  return (
    <div
      className={`d-flex justify-content-between py-0 px-0 ${scss.extPopover}`}
    >
      <div className='d-flex flex-row'>
        <div>
          <img
            src={`http://www.google.com/s2/favicons?domain=${hostname}`}
            alt={hostname}
            className={scss.extFavIcon}
            onLoad={() => setShowImg(true)}
            style={showImg ? {} : { display: 'none' }}
          />
        </div>

        <div className='d-flex flex-column py-0 px-0 ms-1'>
          <div>
            <a
              href={externalUrl}
              target='_blank'
              rel='noopener noreferrer nofollow'
              className={scss.extPopoverLink}
            >
              {`${protocol}//${hostname}`}
            </a>
          </div>
          <div className={scss.extPopoverLinkInfo}>Visit the external link</div>
        </div>
      </div>

      <div>
        <IconButton
          icon='GoLinkExternal'
          size={iconSize}
          onClick={() => window.open(externalUrl as string, '_blank')}
          className={scss.extPopoverGotoLinkIcon}
        />
      </div>
    </div>
  )
}

export default ExternalLinkPopOver
