// libraries
import { useMemo, CSSProperties } from 'react'
import { useTheme, Theme } from '@emotion/react'
import _ from 'lodash'

// types
import type { StylesConfig } from 'react-select'
import type { ColourArray } from 'types/common'

// constants
import { MULTISELECT_MENU_PORTAL_Z_INDEX } from 'constants/common'

const BORDER_RADIUS = '4px'

const getInvalidStyle = (
  data: { isValid?: boolean },
  invalidStyle: string,
  validStyle: string
) => (_.get(data, 'isValid', true) ? validStyle : invalidStyle)

const getBgColor = ({
  theme,
  bgColour,
  hasError,
  isDisabled,
  readOnly,
}: {
  theme: Theme
  bgColour?: ColourArray | string
  hasError?: boolean
  isDisabled?: boolean
  readOnly?: boolean
}) => {
  if (hasError) {
    return theme['red-50']
  }

  if (readOnly) return bgColour || 'white'

  if (isDisabled) {
    return 'var(--bs-secondary-bg)'
  }

  return bgColour || 'white'
}

const getBorderColor = ({
  theme,
  withBorder,
  isFocused,
  hasError,
}: {
  theme: Theme
  withBorder?: boolean
  isFocused?: boolean
  hasError?: boolean
}) => {
  if (withBorder) {
    if (hasError) return theme['red-500']
    return isFocused ? theme.primary : theme['secondary-100']
  }
  return 'transparent'
}

const DEFAULT_LINE_HEIGHT = 1.3

const getMultiValueLineHeight = ({
  isLarge,
  hasValue,
}: {
  isLarge?: boolean
  hasValue?: boolean
}) => {
  if (isLarge) return hasValue ? DEFAULT_LINE_HEIGHT : 2.2
  return hasValue ? DEFAULT_LINE_HEIGHT : 1.5
}

const getValueContainerStyles = ({
  isLarge,
  isMulti,
  hasValue,
}: {
  isLarge?: boolean
  isMulti?: boolean
  hasValue?: boolean
}) => {
  if (isMulti)
    return {
      padding: isLarge ? '6px 10px' : '4px 8px',
      lineHeight: getMultiValueLineHeight({ isLarge, hasValue }),
    }
  return {
    padding: isLarge ? '10px 14px' : '5px 12px',
    lineHeight: DEFAULT_LINE_HEIGHT,
  }
}

const getOptionTextColour = ({
  isOptionDisabled,
  isSelected,
  isFocused,
  customColour,
  theme,
}: {
  isOptionDisabled?: boolean
  isSelected?: boolean
  isFocused?: boolean
  customColour?: string
  theme: Theme
}) => {
  if (isOptionDisabled) return '#ccc'
  if (isSelected || isFocused) return theme['primary-500']
  return customColour || '#141415'
}

const useSelectStyles = ({
  bgColour,
  withBorder = false,
  removeControl = false,
  dropdownOnly = false,
  hasError = false,
  isLarge = false,
  // 1 => 2 to overlap slider's handle
  zIndex = 2,
  readOnly = false,
}: {
  withBorder?: boolean
  bgColour?: ColourArray | string
  removeControl?: boolean
  dropdownOnly?: boolean
  hasError?: boolean
  isLarge?: boolean
  zIndex?: number | string
  readOnly?: boolean
}): {
  customStyles: StylesConfig
} => {
  const theme = useTheme() as Theme
  const dropdownWithControl = dropdownOnly && !removeControl

  const customStyles = useMemo((): StylesConfig => {
    const fontStyle = {
      fontFamily: 'Open Sans',
      fontSize: isLarge ? '16px' : '12px',
    }

    return {
      control: (
        styles: CSSProperties,
        { isFocused, isDisabled }: { isFocused: boolean; isDisabled: boolean }
      ) => {
        // https://github.com/JedWatson/react-select/blob/b0411ff46bc1ecf45d9bca4fb58fbce1e57f847c/packages/react-select/src/components/Control.js#L29-L58

        return {
          ...styles,
          display: removeControl ? 'none' : 'flex',
          minHeight: '31px',
          borderRadius: '3px',
          backgroundColor: getBgColor({
            theme,
            bgColour,
            hasError,
            isDisabled,
            readOnly,
          }),
          outline: '0 !important',
          margin: '0',
          boxShadow: null,
          borderColor: getBorderColor({
            theme,
            withBorder,
            isFocused,
            hasError,
          }),
          ...(isDisabled && {
            opacity: 0.5,
          }),
          ...(readOnly && {
            opacity: 1,
          }),
          ...(dropdownWithControl && {
            borderTopLeftRadius: BORDER_RADIUS,
            borderTopRightRadius: BORDER_RADIUS,
          }),
          '&:hover': {
            borderColor: getBorderColor({
              theme,
              withBorder,
              isFocused,
              hasError,
            }),
          },
        }
      },
      option: (
        styles: CSSProperties,
        {
          data,
          isDisabled: isOptionDisabled,
          isFocused,
          isSelected,
        }: {
          data: { colour?: string }
          isDisabled: boolean
          isFocused: boolean
          isSelected: boolean
        }
      ) => {
        return {
          ...styles,
          ...fontStyle,
          fontWeight: 'regular',
          backgroundColor: isOptionDisabled
            ? null
            : isSelected || isFocused
            ? theme['secondary-light-500']
            : null,
          color: getOptionTextColour({
            isOptionDisabled,
            isSelected,
            isFocused,
            customColour: data?.colour,
            theme,
          }),
          cursor: isOptionDisabled ? 'not-allowed' : 'default',
          ':active': {
            ...styles[':active'],
            backgroundColor: !isOptionDisabled && theme['secondary-light-500'],
          },
        }
      },
      input: (styles: CSSProperties) => ({ ...styles }),
      placeholder: (styles: CSSProperties) => ({
        ...styles,
        ...fontStyle,
      }),
      valueContainer: (styles: CSSProperties, { isMulti, hasValue }) => ({
        ...styles,
        color: theme['primary-500'],
        ...getValueContainerStyles({ isLarge, isMulti, hasValue }),
      }),
      singleValue: (styles: CSSProperties) => ({
        ...styles,
        ...fontStyle,
        color: '#222529',
      }),
      multiValue: (
        styles: CSSProperties,
        { data }: { data: { isValid?: boolean } }
      ) => ({
        ...styles,
        backgroundColor: getInvalidStyle(
          data,
          theme['red-50'],
          theme['secondary-light-500']
        ),
      }),
      multiValueLabel: (
        styles: CSSProperties,
        { data }: { data: { isValid?: boolean } }
      ) => ({
        ...styles,
        color: getInvalidStyle(data, theme['red-500'], theme.primary),
        padding: isLarge ? '6px 6px 6px 8px' : '3px 3px 3px 5px',
        ...fontStyle,
      }),
      multiValueRemove: (
        styles: CSSProperties,
        { data }: { data: { isValid?: boolean } }
      ) => ({
        ...styles,
        color: getInvalidStyle(data, theme['red-500'], theme.primary),
      }),
      dropdownIndicator: (
        styles: CSSProperties,
        { isDisabled }: { isDisabled: boolean }
      ) => ({
        ...styles,
        padding: '0 3px',
        display: isDisabled ? 'none' : 'block',
        borderLeft: withBorder ? theme['secondary-200'] : 'none',
      }),
      indicatorSeparator: (
        styles: CSSProperties,
        { isDisabled }: { isDisabled: boolean }
      ) => ({
        ...styles,
        display: isDisabled ? 'none' : 'block',
      }),
      clearIndicator: (styles: CSSProperties) => ({
        ...styles,
        padding: '5px',
      }),
      menu: (styles: CSSProperties) => ({
        ...styles,
        marginTop: '0.5px',
        zIndex,
        ...(dropdownWithControl && {
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        }),
      }),
      // When using a 'portal' in a modal, make sure the menu is visible
      menuPortal: (styles: CSSProperties) => ({
        ...styles,
        zIndex: MULTISELECT_MENU_PORTAL_Z_INDEX,
      }),
      noOptionsMessage: (styles: CSSProperties) => ({
        ...styles,
        ...fontStyle,
      }),
      loadingMessage: (styles: CSSProperties) => ({
        ...styles,
        ...fontStyle,
      }),
    }
  }, [
    bgColour,
    theme,
    withBorder,
    removeControl,
    dropdownWithControl,
    hasError,
    isLarge,
    zIndex,
    readOnly,
  ])

  return { customStyles }
}

export default useSelectStyles
