import React, { useMemo, useCallback, useState, useEffect } from 'react'
import update from 'immutability-helper'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { DndContext, DragOverlay } from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'

// utils
import { isStringContainsCharacters } from 'helpers/utils'

// constants
import { BUTTON_VARIANTS, BUTTON_SIZES } from 'components/common/Button'

// components
import { ListGroup, SearchBar, Button } from 'components/common'
import SortableItem from 'components/common/PropertiesList/SortableItem'
import Item from 'components/common/PropertiesList/Item'

const findIndex = (items, id, identifier) =>
  _.findIndex(items, { [identifier]: id })

const updateList = ({ items, id, payload, identifier, onChange }) => {
  const index = findIndex(items, id, identifier)
  const newItems = update(items, {
    [index]: { $merge: payload },
  })
  onChange(newItems)
  return newItems
}

const appendIdentifier = identifier => p => ({ ...p, id: p[identifier] })

const getPropertiesWithIdentifier = (properties, identifier) =>
  properties.map(appendIdentifier(identifier))

const PropertiesList = ({
  properties,
  onChange,
  onItemChange,
  identifier,
  searchIdentifier,
  withWidgetSelect,
  reservedFields,
  disableSort,
}) => {
  const [items, setItems] = useState(() =>
    getPropertiesWithIdentifier(properties, identifier)
  )

  const itemsByIdentifier = useMemo(
    () => _.keyBy(items, `${identifier}`),
    [identifier, items]
  )

  const [activeId, setActiveId] = useState()

  useEffect(() => {
    setItems(getPropertiesWithIdentifier(properties, identifier))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [properties])

  const handleDragStart = useCallback(({ active }) => {
    setActiveId(active.id)
  }, [])

  const handleDragEnd = useCallback(
    ({ active, over }) => {
      if (!disableSort && active.id !== over.id) {
        let newItems
        setItems(oldItems => {
          const oldIndex = findIndex(oldItems, active.id, identifier)
          const newIndex = findIndex(oldItems, over.id, identifier)
          newItems = arrayMove(oldItems, oldIndex, newIndex)
          return newItems
        })
        onChange(newItems)
      }
      setActiveId()
    },
    [disableSort, onChange, identifier]
  )

  return (
    <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        <ListGroup>
          {_.map(items, item => {
            const id = item[identifier]
            return (
              <SortableItem
                key={id}
                id={id}
                item={item}
                identifier={identifier}
                withWidgetSelect={withWidgetSelect}
                searchIdentifier={searchIdentifier}
                reservedFields={reservedFields}
                disableSort={disableSort}
                onChange={payload => onItemChange(id, payload)}
              />
            )
          })}
        </ListGroup>
      </SortableContext>
      <DragOverlay>
        {activeId && (
          <Item
            id={activeId}
            item={itemsByIdentifier[activeId]}
            identifier={identifier}
            withWidgetSelect={withWidgetSelect}
            searchIdentifier={searchIdentifier}
            d
          />
        )}
      </DragOverlay>
    </DndContext>
  )
}

PropertiesList.propTypes = {
  properties: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onChange: PropTypes.func.isRequired,
  onItemChange: PropTypes.func.isRequired,
  identifier: PropTypes.string,
  searchIdentifier: PropTypes.string,
  withWidgetSelect: PropTypes.bool,
  reservedFields: PropTypes.arrayOf(PropTypes.string),
  disableSort: PropTypes.bool,
}

PropertiesList.defaultProps = {
  identifier: 'name',
  searchIdentifier: 'label',
  withWidgetSelect: false,
  reservedFields: [],
  disableSort: false,
}

const PropertiesListContainer = props => {
  const {
    onChange,
    properties,
    identifier,
    searchIdentifier,
    title,
    reservedFields,
  } = props

  const [propertiesList, setPropertiesList] = useState(properties)

  const [filteredProperties, setFilteredProperties] = useState(propertiesList)

  const onPropertiesListChange = useCallback(
    newList => {
      setPropertiesList(newList)
      onChange(newList)
    },
    [onChange]
  )

  useEffect(() => {
    const getSortedNames = list => _(list).map('name').sort().value()

    const hasPropertiesChanged = !_.isEqual(
      getSortedNames(properties),
      getSortedNames(propertiesList)
    )

    if (hasPropertiesChanged) {
      setPropertiesList(properties)
      setFilteredProperties(properties)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [properties])

  const onSearchChange = useCallback(
    ({ value: query = '' }) => {
      const filteredList =
        _.isNil(query) || query === ''
          ? propertiesList
          : propertiesList.filter(({ [searchIdentifier]: label }) =>
              isStringContainsCharacters(label, query)
            )
      setFilteredProperties(filteredList)
    },
    [propertiesList, searchIdentifier]
  )

  const areFiltersApplied = useMemo(
    () => filteredProperties.length !== propertiesList.length,
    [filteredProperties.length, propertiesList.length]
  )

  const onToggleAllFilteredProperties = useCallback(
    isVisible => {
      const newFilteredProperties = filteredProperties.map(property => {
        return {
          ...property,
          isVisible:
            _.includes(reservedFields, property[identifier]) || isVisible,
        }
      })

      setFilteredProperties(newFilteredProperties)

      const newFilteredPropertiesByIdentifier = _.keyBy(
        newFilteredProperties,
        `${identifier}`
      )
      const newPropertyList = areFiltersApplied
        ? propertiesList.map(property => {
            return (
              newFilteredPropertiesByIdentifier[property[identifier]] ||
              property
            )
          })
        : newFilteredProperties
      onPropertiesListChange(newPropertyList)
    },
    [
      filteredProperties,
      identifier,
      areFiltersApplied,
      propertiesList,
      onPropertiesListChange,
      reservedFields,
    ]
  )

  const onItemChange = useCallback(
    (id, payload) => {
      const sharedProps = { id, payload, identifier }
      updateList({
        ...sharedProps,
        items: propertiesList,
        onChange: onPropertiesListChange,
      })
      updateList({
        ...sharedProps,
        items: filteredProperties,
        onChange: setFilteredProperties,
      })
    },
    [filteredProperties, identifier, onPropertiesListChange, propertiesList]
  )

  const className = useMemo(
    () =>
      title &&
      'd-flex justify-content-between align-items-center groupOptionTitle',
    [title]
  )

  return (
    <div className='groupOption'>
      <div className={className}>
        <div>{title}</div>
        <div className='d-flex'>
          <Button
            className='p-0 me-2'
            variant={BUTTON_VARIANTS.link}
            onClick={() => onToggleAllFilteredProperties(true)}
            size={BUTTON_SIZES.small}
          >
            Enable All
          </Button>
          <Button
            className='p-0'
            variant={BUTTON_VARIANTS.link}
            onClick={() => onToggleAllFilteredProperties(false)}
            size={BUTTON_SIZES.small}
          >
            Disable All
          </Button>
        </div>
      </div>
      <SearchBar onChange={onSearchChange} />
      <PropertiesList
        {...props}
        properties={filteredProperties}
        onChange={newItems => {
          onPropertiesListChange(newItems)
          setFilteredProperties(newItems)
        }}
        onItemChange={onItemChange}
        disableSort={areFiltersApplied}
      />
    </div>
  )
}

PropertiesListContainer.propTypes = {
  properties: PropTypes.arrayOf(PropTypes.shape({})),
  onChange: PropTypes.func.isRequired,
  identifier: PropTypes.string,
  searchIdentifier: PropTypes.string,
  withWidgetSelect: PropTypes.bool,
  title: PropTypes.string,
  reservedFields: PropTypes.arrayOf(PropTypes.string),
}

PropertiesListContainer.defaultProps = {
  properties: [],
  identifier: 'name',
  searchIdentifier: 'label',
  withWidgetSelect: false,
  title: 'Arrange properties',
  reservedFields: [],
}

export default PropertiesListContainer
