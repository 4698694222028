import { ReactElement, useMemo, useCallback, useState } from 'react'
import { useToggle, useUpdateEffect } from 'react-use'

// types
import type { Filters, OnFiltersChange, DateFilterOptions } from 'types/filter'

// constants
import {
  DATE_RANGE_FILTER_OPTIONS,
  DATE_RANGE_FILTER_POSSIBLE_VALUES,
} from 'constants/filter'
import { TOOLTIP_PLACEMENT } from 'constants/settings'

// helpers
import { useStaticResourceStateValue } from 'contexts'
import { useTimezone } from 'hooks'
import { getDateFilterLabel } from 'helpers/filter'

// components
import { ControllableDropdown } from 'components/common'
import DateTimeFilterBody from 'components/common/List/FilterBuilder/DateTimeFilterBody'
import { Tooltip } from 'components/common/Tooltip'

import scss from './index.module.scss'

const DROPDOWN_ALIGN_CONFIG = {
  offset: [0, -5],
}

const ICON_SIZE = 14

const isRelativeTime = (date?: string) =>
  DATE_RANGE_FILTER_POSSIBLE_VALUES.includes(date as string)

const TableDateRangeFilter = ({
  filterValues,
  dateFilterOptions,
  onFiltersChange,
}: {
  filterValues?: Filters
  dateFilterOptions: DateFilterOptions
  onFiltersChange: OnFiltersChange
}): ReactElement => {
  const {
    getDateRangeFromFilter,
    transformSelectedRangeForFilter,
    tooltipText,
    maxDaysRange,
  } = dateFilterOptions

  const { startDate, endDate } = useMemo(
    () => getDateRangeFromFilter(filterValues),
    [filterValues, getDateRangeFromFilter]
  )

  const [value, setValue] = useState([startDate, endDate])

  useUpdateEffect(() => {
    const [newStartDate, newEndDate] = value

    // Do nothing until user select the end date
    if (!isRelativeTime(newStartDate) && !newEndDate) return

    onFiltersChange({
      ...filterValues,
      ...transformSelectedRangeForFilter(value),
    })
  }, [value, transformSelectedRangeForFilter])

  const { Icons } = useStaticResourceStateValue()
  const [menuIsOpen, setMenuIsOpen] = useToggle(false)
  const { timezone } = useTimezone()

  const renderToggle = useCallback(() => {
    const label = getDateFilterLabel({
      startDate,
      endDate,
      timezone,
      options: DATE_RANGE_FILTER_OPTIONS,
    })

    const toggle = (
      <div className='d-flex align-items-center'>
        <div className='d-flex me-2'>
          <Icons.DatePickerIcon
            size={ICON_SIZE}
            width={ICON_SIZE}
            height={ICON_SIZE}
          />
        </div>
        {label}
      </div>
    )

    return (
      <div className={scss.trigger}>
        {tooltipText ? (
          <Tooltip
            placement={TOOLTIP_PLACEMENT.top}
            overlay={<span>{tooltipText}</span>}
          >
            {toggle}
          </Tooltip>
        ) : (
          toggle
        )}
      </div>
    )
  }, [Icons, startDate, endDate, timezone, tooltipText])

  const handleRangeChange = useCallback(
    (newValue: [string, string]) => {
      const [newStartDate, newEndDate] = newValue

      setValue(newValue)

      // Close the dropdown if some of the default values is selected
      if (isRelativeTime(newStartDate) || !!newEndDate) {
        setMenuIsOpen(false)
      }
    },
    [setValue, setMenuIsOpen]
  )

  return (
    <ControllableDropdown
      isVisible={menuIsOpen}
      toggleListVisible={setMenuIsOpen}
      placement={TOOLTIP_PLACEMENT.bottom}
      trigger='click'
      align={DROPDOWN_ALIGN_CONFIG}
      toggleComponent={renderToggle}
    >
      <DateTimeFilterBody
        value={value}
        onTimeChange={handleRangeChange}
        options={DATE_RANGE_FILTER_OPTIONS}
        // 3 months
        maxDaysRange={maxDaysRange}
      />
    </ControllableDropdown>
  )
}

export default TableDateRangeFilter
