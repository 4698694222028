// libraries
import { FC, ReactElement } from 'react'
import _ from 'lodash'

// constants
import { TOOLTIP_PLACEMENT } from 'constants/settings'

// components
import { TruncatingTextWithTooltip } from 'components/common/TruncatingTextWithTooltip'

import type { ColumnBodyOptions } from 'primereact/column'

type PlainTextTemplateProps = {
  rowData: unknown
  options: ColumnBodyOptions
}

const PlainTextTemplate: FC<PlainTextTemplateProps> = (
  rowData,
  options
): ReactElement => {
  const { field } = options || {}
  const text = _.get(rowData, field)

  return (
    <TruncatingTextWithTooltip
      className='text-capitalize'
      placement={TOOLTIP_PLACEMENT.top}
      title={text}
    >
      <span>{text}</span>
    </TruncatingTextWithTooltip>
  )
}

export default PlainTextTemplate
