// libraries
import { useCallback, ReactElement } from 'react'

// constants
import { HOME_PAGE_URL } from 'constants/common'

// components
import { Cover } from 'components/common'

const AppError = ({
  message = '',
  refreshButtonContent = 'Refresh',
  redirectUrl = HOME_PAGE_URL,
}: {
  message?: string
  refreshButtonContent?: string
  redirectUrl?: string
}): ReactElement => {
  const handleClick = useCallback(() => {
    window.location.href = redirectUrl
  }, [redirectUrl])

  return (
    <Cover
      onClick={handleClick}
      buttonContent={refreshButtonContent}
      message={
        <>
          <h2 className='fw-lighter'>Something went wrong </h2>
          <h4> {message}</h4>
        </>
      }
    />
  )
}

export default AppError
