import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { THEMES } from 'constants/colour'
import { DEFAULT_ASSET_WIDGET_DIVIDER_STYLE } from 'constants/assets'

import { colourArrToRgbaStr } from 'helpers/colour'

const AssetTextWidget = ({ settings, theme }) => {
  const isLightTheme = useMemo(() => theme === THEMES.light, [theme])
  const { colour, height, opacity } = settings

  const color = useMemo(() => {
    if (_.isEmpty(colour)) return isLightTheme ? 'black' : 'white'

    return colourArrToRgbaStr(colour)
  }, [colour, isLightTheme])

  return (
    <div className='d-flex align-items-center justify-content-center h-100'>
      <hr
        style={{
          border: `${
            height || DEFAULT_ASSET_WIDGET_DIVIDER_STYLE.height
          }px solid ${color}`,
          width: '100%',
          margin: 0,
          opacity,
        }}
      />
    </div>
  )
}

AssetTextWidget.propTypes = {
  settings: PropTypes.shape({
    colour: PropTypes.arrayOf(PropTypes.number),
    height: PropTypes.number,
    opacity: PropTypes.number,
  }).isRequired,
  theme: PropTypes.oneOf([THEMES.dark, THEMES.light]),
}

AssetTextWidget.defaultProps = {
  theme: THEMES.dark,
}

export default AssetTextWidget
