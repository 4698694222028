// constants
import { ENTITY_OWNER_FIELDS } from 'constants/graphql'
import { ENTITIES } from 'constants/common'

// utils
import { reportGraphqlError, getQueryFields } from 'helpers/graphql'
import { updateSiteTypeForCASL } from 'helpers/site'
import {
  getEntitiesQuery,
  getEntityGraphql,
  getEntityQuery,
  listEntitiesGraphql,
} from 'services/api/utils'
import { fetchGraphQLListData } from 'helpers/utils'

// constants
import { SITE_FIELDS } from 'services/api/site'

import type { Site } from 'types/sites'
import type { SelectedFields } from 'types/services'
import type { Option } from 'types/common'

import { deserializeSite as deserializeSiteForDetection } from './detection'

const domain = ENTITIES.site
const queryDomain = `${domain}s`

export const reportSiteError = reportGraphqlError(queryDomain)

const deserializeSite = (site: Site): Site => {
  const newSite = updateSiteTypeForCASL(site)
  const { thumbUrl, properties, ...rest } = newSite
  return {
    ...rest,
    ...(properties || {}),
    site: deserializeSiteForDetection(site),
    basin: properties?.userProperties?.basin,
  }
}

const getSiteFields = getQueryFields({
  id: true,
  active: true,
  outlier: true,
  outlierExplanation: true,
  ...ENTITY_OWNER_FIELDS,
  observation: true,
  properties: {
    name: true,
    userProperties: true,
  },
})

const commonListSitesProps = {
  queryDomain,
  queryName: null,
}

const geSitesQuery = getEntitiesQuery<Site>({
  ...commonListSitesProps,
  variables: {
    first: 'Int',
    after: 'String',
    filter: 'SiteFilter',
  },
  getFieldsFn: getSiteFields,
})

export const listSites = listEntitiesGraphql<Site>({
  ...commonListSitesProps,
  queryDisplayName: 'GetMethaneAllSites',
  getQueryFn: geSitesQuery,
  postProcessFn: deserializeSite,
  isSingleEntityPostProcessFn: true,
  enableLoadMore: false,
})

const METHANE_SITE_FIELDS = {
  deepDiveProperties: {
    underDeepDive: true,
  },
}

const getMethaneSiteFields = getQueryFields({
  ...SITE_FIELDS,
  ...METHANE_SITE_FIELDS,
  observations: true,
  observation: true,
})

const getMethaneSiteByIdQuery = getEntityQuery({
  queryName: domain,
  getFieldsFn: getMethaneSiteFields,
})

export const getMethaneSite = (
  props: SelectedFields = {}
): ((id: string) => Promise<Site>) =>
  getEntityGraphql<Site>({
    queryDomain: domain,
    getQueryFn: getMethaneSiteByIdQuery,
    queryDisplayName: 'GetMethaneSiteById',
    queryName: null,
    ...props,
  })

export const loadSitesForDropdown = async ({
  searchTerm,
  itemsPerRequest = 20,
  transformToOptions,
}: {
  searchTerm: string
  itemsPerRequest?: number
  transformToOptions?: (data: Site[]) => Option[]
}): Promise<Site[] | Option[] | []> => {
  const result = await fetchGraphQLListData({
    fetchFunc: listSites,
    queryParams: {
      filter: { search: { query: searchTerm }, active: true },
      first: itemsPerRequest,
    },
  })

  if (transformToOptions) {
    return result?.data ? transformToOptions(result.data as Site[]) : []
  }

  return (result?.data as Site[]) ?? []
}
