/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

// libraries
import { KeyboardEvent } from 'react'
import _ from 'lodash'
import objectHash from 'object-hash'
import { customAlphabet } from 'nanoid'
import to from 'await-to-js'
import isEqual from 'fast-deep-equal'
import moment from 'moment-timezone'
import update from 'immutability-helper'
import url from 'url'
import DOMPurify from 'dompurify'
import { map as awaityMap } from 'awaity/esm'
import type { SyntheticEvent } from 'react'
import UAParser from 'ua-parser-js'
import { matchRoutes } from 'react-router-dom'

// constants
import { SUPPORTED_FILTER_PROPERTY_TYPES } from 'constants/filter'
import { UTC, MONTH_YEAR_AM_PM } from 'constants/datetime'
import {
  OPERATION_OPTIONS,
  THROTTLE_DELAY,
  ENTITY_USERNAME_PATH,
  ROUTE_OPTIONAL_PARAMS_REGEX,
} from 'constants/common'
import { AGGREGATION_TYPES } from 'constants/aggregation'

// utils
import {
  isValidISODatetimeString,
  getMomentFromUtcDateTime,
} from 'helpers/datetime'

import type {
  Payload,
  Range,
  Options,
  PropertiesMetadata,
  InternalPropertiesMetadata,
  FetchFunc,
  FetchListData,
  OptionProps,
  Option,
  NetworkState,
  CustomNavigator,
  ActionType,
} from 'types/common'
import type { Entity } from 'types/entity'
import type { PageInfo } from 'types/graphql'
import type { User } from 'types/user'
import type { ColumnsWithGroupable } from 'components/common/DataTable/useDataTableColumns'
import type { RelayStyleData } from 'services/api/utils'
import type { Validator } from './validators'

export const getEntityOwnerUsername = (entity: Entity): string => {
  return _.get(entity, ENTITY_USERNAME_PATH)
}

/**
 * matches common truth values in parameters passed into environment variables, etc.
 * 0, false, f should return false, 1, true, t should return true
 * not strings return false
 * all other strings return false (default to false for safety reasons)
 * @param {String} inString - The string.
 *
 * @return {Boolean}
 */
export const isTrueString = (inString: string): boolean => {
  // handle not string by returning false (not a string, so not a true string)
  if (!_.isString(inString)) return false
  if (inString === '0' || /^false$/i.test(inString) || /^f$/i.test(inString)) {
    return false
  }
  return !!(
    inString === '1' ||
    /^true$/i.test(inString) ||
    /^t$/i.test(inString)
  )
}

const getSearchParamsFromPath = (routePath: string) => {
  // Matching search params /maps/:mapId?edit => ?edit
  const matchResult = routePath.match(/\?[^/:]*$/g)

  const searchParams = matchResult?.[0] || ''
  // Could match only '?' if there is an optional param in the end,
  // so checking if there is more than 1 character matched
  const hasSearchParams = searchParams.length > 1
  // If the route has search params, remove them to get a clean path
  const cleanPath = hasSearchParams
    ? routePath.replace(searchParams, '')
    : routePath

  return { cleanPath, hasSearchParams, searchParams }
}

export const getRouteUrlWithValues = (
  routeUrl = '',
  routeValues?: Payload
): string => {
  const { cleanPath, hasSearchParams, searchParams } =
    getSearchParamsFromPath(routeUrl)

  const newUrl = _.reduce(
    routeValues,
    (route, value, key) => {
      const param = `:${key}`
      const optionalParam = `${param}?`
      const isOptional = route.includes(optionalParam)
      // If the param is optional, don't forget to replace '?' in the end
      return route.replace(isOptional ? optionalParam : param, value)
    },
    cleanPath
  )

  const optionalLeftover = newUrl.match(ROUTE_OPTIONAL_PARAMS_REGEX)
  // Cleaning up optional params
  if (optionalLeftover && optionalLeftover[0]) {
    return newUrl.replace(optionalLeftover[0], '')
  }

  return hasSearchParams ? `${newUrl}${searchParams}` : newUrl
}

export const stopEventDefaultAndPropagation = (
  event:
    | React.MouseEvent<HTMLButtonElement | HTMLDivElement | HTMLElement>
    | SyntheticEvent<Element, Event>
): void => {
  event.preventDefault()
  event.stopPropagation()
}

/**
 * Check if the input range is valid. The start value must be less than or equal to the end value
 * @param {Array} range
 * @param {Boolean} allowEqual
 * @return {Boolean} true if the range is valid; false otherwise
 */
export const isRangeValid = (range?: Range, allowEqual = true): boolean => {
  if (!range || _.isEmpty(range)) return false

  const [start, end] = range
  return (
    _.isNumber(start) &&
    _.isNumber(end) &&
    (allowEqual ? start <= end : start < end)
  )
}

/**
 * Get an object contains the min and max value of the input valid range
 * @param {Array} range
 * @return {Object} {min,max}
 */
export const getRangeMinMaxObj = (
  range: [number, number]
): { min?: number; max?: number } => {
  return isRangeValid(range) ? { min: range[0], max: range[1] } : {}
}

export const isValueInRange = (
  value: number,
  range: [number, number],
  includesEnd = false
): boolean => {
  if (!isRangeValid(range)) return true

  const [start, end] = range
  return (
    value === start ||
    (includesEnd && value === end) ||
    _.inRange(value, start, end)
  )
}

export const numberSort = (a: number, b: number): boolean => !!(a - b)

export const fetchListData = async (
  fetchFunc: {
    ({ omitFields, pickFields, ...rest }: FetchFunc): Promise<{
      data: unknown[]
      error?: string | undefined
      pageInfo: PageInfo
    }>
    (...args: unknown[]): unknown
    ({ omitFields, pickFields, ...rest }: FetchListData): Promise<{
      data: (Omit<User, 'preferences'> & {
        preferences: {
          preference: string // handle not string by returning false (not a string, so not a true string)
          // handle not string by returning false (not a string, so not a true string)
          value: unknown
        }[]
      })[]
      error?: string | undefined
      pageInfo: PageInfo
    }>
    (arg0: unknown): Promise<unknown>
  },
  queryParams = {}
): Promise<unknown[]> => {
  let list: _.List<unknown> | null | undefined = []
  let nextToken
  do {
    const [error, result] = await to(
      fetchFunc({ ...queryParams, ...(nextToken && { nextToken }) })
    )
    if (error) {
      throw error
    }

    list = _.concat(list, result.data)
    nextToken = result.nextToken
  } while (nextToken)
  return _.compact(list)
}

export const fetchGraphQLListData = async ({
  fetchFunc,
  queryParams,
  abortController,
  ...rest
}: {
  fetchFunc: FetchFunc
  queryParams: Payload
  abortController?: AbortController
}): Promise<RelayStyleData<unknown> | undefined> => {
  const [error, result] = await to(
    fetchFunc({ ...rest, queryParams, abortController })
  )

  if (error) {
    throw error
  }

  return result
}

/**
 * Get all valid values of the given property from the given data collection
 * @param {Array} collection: the collection to iterate over
 * @param {String} key : the property name
 * @param {Array}: valid property values array if the key is not empty,
 * otherwise return the original collection
 */
export const getValidPropertyValues = (
  collection: any,
  key: string
): Payload[] =>
  _.isEmpty(key) ? collection : _(collection).map(key).compact().value()

export const stripBom = (str: string): string => {
  if (!str || !_.isString(str)) return str
  //  TODO: use replaceAll after the AWS CodeBuild supports Node.js 15+
  // https://docs.aws.amazon.com/codebuild/latest/userguide/runtime-versions.html
  // return str.replaceAll('\uFEFF', '').replaceAll('&#xFEFF;', '')
  return str.replace(/\uFEFF/g, '').replace(/&#xFEFF;/g, '')
}

export const sanitizeString = (
  str?: string,
  multiline = false
): string | undefined => {
  if (_.isNil(str)) return undefined

  if (!_.isString(str)) return str

  const sanitized = DOMPurify.sanitize(str, {
    USE_PROFILES: { html: false },
  })
  // Do not remove line breaks if it's a multiline text (eg textarea)
  return multiline ? sanitized : sanitized.replace(/\n/g, '')
}

export const removeEmptyParagraphTags = (content: string): string => {
  const sanitizedContent = content.replace(/<br\s*\/?>/gi, '')
  const removeEmptyParagraph = sanitizedContent.replace(
    /<p[^>]*>(\s|&nbsp;)*<\/p>/gi,
    ''
  )

  return DOMPurify.sanitize(removeEmptyParagraph)
}

export const shouldSanitizeStr = (str: string | undefined): boolean => {
  if (_.isNil(str)) return false

  return !_.isEqual(sanitizeString(str), str)
}

export const removeNullValues = (property: {
  name?: string
  displayName?: null
  type?: string
  format?: null
  termsCompleteness?: null
}): Payload =>
  _.reduce(
    property,
    (acc, value, key) => {
      if (_.isNil(value)) return acc
      return { ...acc, [key]: value }
    },
    {}
  )

export const capitalizeFirstLetter = (str = ''): string =>
  str.charAt(0).toUpperCase() + str.slice(1)

/**
 * Transform property structure
 * @param {Object} property
 *
 * @return {Object} organized property
 */
const getOrganizedProperty = (property: Payload): Payload => {
  const validPropertyValues = removeNullValues(property)
  const { name: key, displayName } = validPropertyValues as Payload & {
    displayName: string
    name: string
  }
  return {
    ...validPropertyValues,
    key,
    value: key,
    label: sanitizeString(displayName) || capitalizeFirstLetter(key),
  }
}

/**
 * Re-organize the properties structure
 * @param {Object} properties: property list
 *
 * @return {Object} transformed properties
 */
export const transformProperties = (
  properties: PropertiesMetadata
): Payload[] => {
  return _.map(properties, getOrganizedProperty)
}

export const isValidPropertyType = (type: string): boolean =>
  SUPPORTED_FILTER_PROPERTY_TYPES.includes(type)

// add disabled: true to properties that don't have supported type
export const disableInvalidProperties = (properties?: any[]) =>
  properties
    ? properties.map((prop: { type: any }) =>
        isValidPropertyType(prop.type) ? prop : { ...prop, isDisabled: true }
      )
    : []

export const getPayloadFromJwtToken = (token: string): Payload => {
  try {
    const jwtEncodedPayload = token.includes('.') && token.split('.')[1]
    const jwtJsonPayload =
      jwtEncodedPayload && Buffer.from(jwtEncodedPayload, 'base64').toString()
    const jwtPayload = jwtJsonPayload && JSON.parse(jwtJsonPayload)
    return jwtPayload || {}
    // eslint-disable-next-line no-empty
  } catch (e) {}
  return {}
}

/**
 * Format the time based on the given timezone and format
 * @prop {String} datetime
 * @prop {String} [timezone]
 * @prop {String} [timeFormat=MONTH_YEAR_AM_PM]
 * @prop {boolean} [displayZoneAbbr=true] whether force to display the zone abbr, if set to false, then even it is display a date include h, m, s then the ZoneAbbr won't be VISIBLE
 *
 * @return {String} formatted time string in the given format
 */
export const displayTime = ({
  datetime,
  timezone = UTC,
  timeFormat = MONTH_YEAR_AM_PM,
  displayZoneAbbr = true,
}: {
  datetime: string | Date | moment.Moment
  timezone?: string
  timeFormat?: string
  displayZoneAbbr?: boolean
}): string => {
  const momentTime = getMomentFromUtcDateTime(datetime, timezone)
  let formattedTime = momentTime.format(timeFormat)

  if (displayZoneAbbr) {
    const zoneAbbr = moment.tz(timezone).zoneAbbr()
    formattedTime += ` ${zoneAbbr}`
  }

  return formattedTime
}

/**
 * Convert the input value to a string
 * @param {*} value
 * @param {String} [timezone] optional for time
 *
 * @return {String} stringified input value
 */
export const displayValue = (
  value: unknown,
  timezone?: string | undefined
): string => {
  if (_.isNil(value)) return ''

  if (_.isNumber(value))
    return value % 1 !== 0 ? `${value.toFixed(2)}` : `${value}`

  if (_.isArray(value)) return _.flattenDeep(value).join()

  if (_.isObject(value)) return JSON.stringify(value)

  if (_.isString(value) && isValidISODatetimeString(value))
    return displayTime({
      datetime: value,
      timezone,
    })

  return `${value}`
}

/**
 * Convert the input value for table
 * @param {*} value
 *
 * @return {String} stringified input value
 */
export const displayTableValue = (
  value: _.ListOfRecursiveArraysOrValues<unknown> | null | undefined
): string => {
  if (!value) return ''
  if (_.isArray(value)) return _.flattenDeep(value).join()
  if (_.isObject(value)) return JSON.stringify(value)
  return value
}

const alphabet =
  '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'

/**
 * Generate a unique string id of specified length
 * @param {Number} [len=16] id length
 *
 * @return {String} unique string id
 */
export const uniqueStringId = (len = 16): string =>
  customAlphabet(alphabet, len)()

/**
 * Get the hash value from the given object or an empty string if the object is empty/invalid
 */
export const getObjectHash = (object: Payload): string =>
  _.isEmpty(object) ? '' : objectHash(object)

/**
 * A functional switch statement alternative
 * @example
 * switchcase({
 * 'INCREMENT': state + 1,
 * 'DECREMENT': state -1
 * })(state)(key)
 */
export const switchcase =
  (cases: Payload) => (defaultCase: any) => (key?: PropertyKey) =>
    Object.prototype.hasOwnProperty.call(cases, key) ? cases[key] : defaultCase

/**
 * A companion function of switchcase, which is just like switchcase
 * except it's values are functions
 * @example
 * switchcaseF({
 * 'INCREMENT': () => state + 1,
 * 'DECREMENT': () => state -1
 * })(() => state)(key)
 */
export const switchcaseF =
  (cases: Payload) => (defaultCase: any) => (key?: PropertyKey) =>
    switchcase(cases)(defaultCase)(key)()

/**
 * form validators, show all the messages
 */
export const composeValidators =
  (...validators: Validator[]) =>
  (value: unknown): Payload => {
    const errorsList = validators.reduce((allErrors, validator) => {
      const errorString = validator(value)
      if (errorString) {
        allErrors.push(errorString)
      }
      return allErrors
    }, [])

    return errorsList.join(', ')
  }

/**
 * Get an array of unique objects
 * @param {Array} arrList The array of objects to inspect.
 * @param {Array} omitPropertyPaths The property paths to omit.
 * @return {Array} Returns the new duplicate free array.
 */
export const getUniqueList = (
  arrList: _.List<unknown> | null | undefined,
  omitPropertyPaths = []
): _.List<unknown> | null | undefined =>
  _.isEmpty(omitPropertyPaths)
    ? _.uniqWith(arrList, isEqual)
    : _.uniqWith(arrList, (arrVal, othVal) =>
        isEqual(
          _.omit(arrVal, omitPropertyPaths),
          _.omit(othVal, omitPropertyPaths)
        )
      )

export const throttledWrapper = _.throttle(fn => fn(), THROTTLE_DELAY)

const mergeCustomizer =
  (deepMergeArray = false) =>
  // eslint-disable-next-line consistent-return
  (oldValue: any, newValue: any) => {
    if (_.isArray(oldValue)) {
      return deepMergeArray ? [...oldValue, ...newValue] : newValue
    }
  }

/**
 * Update the specified item in the list with the given payload
 * @param {String} id item id
 * @param {Object} payload updated payload
 * @param {Array} [list=[]] list to be updated
 * @param {Boolean} [isSet=false] true means replace the whole item; false means shallow merge the payload with the existing item
 * @param {Boolean} [isDeepMerge=false] true means deep merge the payload with the existing item; false means shallow merge
 * @param {Boolean} [deepMergeArray=false] true means concat array in deep merge; false means replace the old array with the new array
 *
 * @return {Array} updated list
 */
export const updateListItem = <T>(
  id: string,
  payload: Partial<T>,
  list: T[] = [],
  isSet = false,
  isDeepMerge = false,
  deepMergeArray = false
): T[] => {
  if (!_.isArray(list)) return []

  const index = _.findIndex(list, { id })
  if (index < 0) {
    return isSet ? [...list, payload] : list
  }

  const method = isSet ? '$set' : '$merge'
  const newItem = isSet
    ? payload
    : isDeepMerge
    ? _.mergeWith({}, list[index], payload, mergeCustomizer(deepMergeArray))
    : _.merge({}, list[index], payload)
  return update(list, {
    [index]: { [method]: newItem },
  })
}

export const updateList = <T>(
  list: T[],
  type: ActionType,
  payload: Partial<T> & { id: string },
  {
    isSet,
    isDeepMerge,
    deepMergeArray,
    insertOnTop,
  }: {
    isSet?: boolean
    isDeepMerge?: boolean
    deepMergeArray?: boolean
    insertOnTop?: boolean
  } = {}
): T[] => {
  const createItem = () =>
    insertOnTop ? [payload, ...list] : [...list, payload]
  const updateItem = () =>
    updateListItem(
      payload?.id,
      payload,
      list,
      isSet,
      isDeepMerge,
      deepMergeArray
    )
  return switchcaseF({
    [OPERATION_OPTIONS.clone]: createItem,
    [OPERATION_OPTIONS.create]: createItem,
    [OPERATION_OPTIONS.share]: updateItem,
    [OPERATION_OPTIONS.update]: updateItem,
    [OPERATION_OPTIONS.delete]: () => _.reject(list, { id: payload?.id }),
  })(() => list)(type)
}

export const isDevEnvironment = (): boolean =>
  process.env.NODE_ENV !== 'production'

/**
 * Get the message based on the given status
 * @param {Boolean} active
 *
 * @return {String} status message
 */
export const getActiveStatus = (active: boolean): string =>
  active ? 'Active' : 'Inactive'

/**
 * Check if the given aggregation type is count or not
 * @param {String} aggregationType
 *
 * @return {Boolean}
 */
export const isCountAggregation = (aggregationType: string): boolean =>
  aggregationType === AGGREGATION_TYPES.count

/**
 * Join an array by commas and “and”
 * @param {Array} inputArray
 *
 * @return {String}
 */
export const getConnectedStringFromArray = (inputArray: string[]): string => {
  const falsyValuesRemoved = _.compact(inputArray)
  if (_.isEmpty(falsyValuesRemoved)) return ''
  if (falsyValuesRemoved.length === 1) return falsyValuesRemoved[0]
  const firsts = falsyValuesRemoved.slice(0, falsyValuesRemoved.length - 1)
  const last = _.last(falsyValuesRemoved)

  return `${firsts.join(', ')} and ${last}`
}

/**
 * Join all errors of the input array into a string
 * @param {Array} inputArray
 * @param {String} keyword: invalid or required
 *
 * @return {String} error message
 */
export const getErrorMessages = (
  inputArray: _.List<unknown> | null | undefined,
  keyword: string
): string | null => {
  const falsyValuesRemoved = _.compact(inputArray) as string[]
  if (_.isEmpty(falsyValuesRemoved)) return null
  return `${getConnectedStringFromArray(falsyValuesRemoved)} ${
    falsyValuesRemoved.length > 1 ? 'are' : 'is'
  } ${keyword}`
}

export const setFinalFormFieldData = (
  [field, newData]: any,
  state: any,
  { changeValue }: any
) => {
  changeValue(state, field, (beforeData: any) => {
    return _.isObject(beforeData) && _.isObject(newData)
      ? { ...beforeData, ...newData }
      : newData
  })
}

export const toLowerCase = (str: string | number | null | undefined): string =>
  `${str}` ? `${str}`.toLowerCase() : ''

export const isLowerCase = (str: string): boolean =>
  !!(str && str === toLowerCase(str))

export const toUpperCase = (str: unknown): string =>
  `${str}` ? `${str}`.toUpperCase() : ''

export const isUpperCase = (str: string): boolean =>
  !!(str && str === toUpperCase(str))

export const isStringContainsCharacters = (
  value: string,
  targetVal: string | number | null | undefined
): boolean => toLowerCase(value).includes(toLowerCase(targetVal))

/**
 * Determine if two input strings values are equivalent or not.
 * @param {String|Number} val: The value to compare.
 * @param {String|Number} targetVal: The other value to compare.
 * @param {Boolean} strict : Indicate whether the comparison is case sensitive or not
 *
 * @return {Boolean}
 */
export const isStringValueEqual = (
  val: string | null,
  targetVal: string | number | null,
  strict = false
): boolean =>
  strict ? val === targetVal : toLowerCase(val) === toLowerCase(targetVal)

export const includesStringValue = (
  list: _.Dictionary<unknown> | null | undefined,
  strVal: string | undefined,
  strict = false
): boolean =>
  strict
    ? _.includes(list, strVal)
    : _(list).map(toLowerCase).includes(toLowerCase(strVal))

export const getBaseURL = (absoluteUrl: string): string => {
  if (!absoluteUrl) return 'https://sensorup.com/'
  const { host, protocol } = url.parse(absoluteUrl)
  return `${protocol}//${host}`
}

export const atob = (str: string): string => Buffer.from(str).toString('base64')

export const btoa = (b64: string): string =>
  Buffer.from(b64, 'base64').toString()

export const objectToBase64 = (obj: Payload): string =>
  atob(JSON.stringify(obj))

export const isPrivateEntity = (entity: Partial<Entity>): boolean =>
  _.get(entity, 'isPrivate', true)

export const isSharedEntity = (entity: Partial<Entity>): boolean =>
  !isPrivateEntity(entity)

export const getFetchOptions = ({ method, payload, token, params = {} }) => {
  const { headersConfig, ...restConfig } = params
  const config = {
    ...restConfig,
    method,
  }

  const headers = _.reduce(
    headersConfig,
    (acc, value, key) => {
      acc.set(key, value)
      return acc
    },
    new Headers()
  )

  if (token) {
    headers.set('Authorization', `Bearer ${token}`)
    headers.set('Access-Control-Request-Headers', 'Authorization')
  }

  if (payload) {
    headers.set('Content-Type', 'application/json')
    config.body = _.isObject(payload)
      ? JSON.stringify(payload)
      : payload.replace(/\n/g, '')
  }

  return { ...config, headers }
}

export const getOptionWithTextTransform =
  ({ textTransformFn, ...rest }: OptionProps = {}) =>
  (value: any): Option => ({
    ...rest,
    value,
    label: _.isFunction(textTransformFn) ? textTransformFn(value) : value,
  })

export const getOption = getOptionWithTextTransform()

export const getOptions = (
  values: Payload,
  configs?: { textTransformFn: (value: unknown) => string }
) => _.map(values, getOptionWithTextTransform(configs))

export const arrayOfStringToArrayOfNumber = (array: string[]) => {
  return _.map(array, _.toNumber)
}

export const getInitialsInLowerCase = (
  name: string | number | null | undefined,
  length = 3
) => {
  if (!name) return undefined

  return `${name}`
    .replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/]/gi, ' ') // replace special characters with space
    .replace(/[^A-Za-z0-9À-ÿ ]/gi, '') // taking care of accented characters as well
    .replace(/ +/gi, ' ') // replace multiple spaces to one
    .split(/ /) // break the name into parts
    .reduce((acc, item) => acc + item[0], '') // assemble an abbreviation from
    .substring(0, length) // get the first two characters an initials
    .toLowerCase()
}

/**
 * Find option from options by key and value
 * @param {*} options
 * @param {*} key
 * @param {*} value
 * @param {Boolean} strict true means case sensitive; false means case insensitive
 *
 * @return {Object|undefined}
 */
export const findOption = (
  options: Options,
  key: string,
  value: string | undefined,
  strict = true
) => {
  if (!key) return undefined

  return _(options)
    .compact()
    .find(option => {
      const optionValue = option[key]
      return strict
        ? optionValue === value
        : toLowerCase(optionValue) === toLowerCase(value)
    })
}

const defaultSortKeyAccessor = (sortField: string) => (d: Payload) => {
  const value = _.get(d, sortField)
  return _.isString(value) ? toLowerCase(_.trim(value) || '') : value
}

export const sortListByProperty = <T>({
  list,
  sortField,
  sortKeyAccessor,
  ascOrder = true,
}: {
  list: T[]
  sortField?: string
  ascOrder?: boolean
  sortKeyAccessor?: (sortKey: string) => string
}): T[] => {
  const iteratee = sortKeyAccessor || defaultSortKeyAccessor
  const order = ascOrder ? 'asc' : 'desc'
  return sortField
    ? (_.orderBy(
        list,
        [d => _.isNil(_.get(d, sortField)), iteratee(sortField)],
        ['asc', order]
      ) as T[])
    : list
}

export const sortKeysByDate = (a: string, b: string) =>
  Date.parse(a) - Date.parse(b)

export const isNil = (value?: string | null) => {
  return (
    _.isNil(value) ||
    toLowerCase(_.trim(value)) === 'null' ||
    toLowerCase(_.trim(value)) === 'undefined'
  )
}

/**
 * Excel sheet name limitation https://support.microsoft.com/en-us/office/rename-a-worksheet-3f1f7148-ee83-404d-8ef0-9ff99fbad1f9?ui=en-us&rs=en-us&ad=us
 * Do not
 * Be blank .
 * Contain more than 31 characters.
 * Contain any of the following characters: / \ ? * : [ ]
 * For example, 02/17/2016 would not be a valid worksheet name, but 02-17-2016 would work fine.
 * Begin or end with an apostrophe ('), but they can be used in between text or numbers in a name.
 * Be named "History". This is a reserved word Excel uses internally.
 * */
const MAX_SHEET_NAME_LENGTH = 31
export const getExcelSheetName = (
  str: string,
  length = MAX_SHEET_NAME_LENGTH
): string => {
  const defaultSheetName = 'sheet'
  if (!str) return defaultSheetName
  if (isStringValueEqual(str, 'History')) return `${str} ${defaultSheetName}`
  const len = _.clamp(length, 1, MAX_SHEET_NAME_LENGTH)

  return `${str}`
    .replace(/[/[*?\]:']/gi, ' ')
    .replace(/[^\u0021-\uFFFF]/gi, ' ')
    .replace(/ +/gi, ' ')
    .substr(0, len)
}

export const getConvertedStringValue = (
  value: string,
  convertStringTypeType?: string
): string =>
  switchcaseF({
    lowerCase: () => toLowerCase(value),
    snakeCase: () => _.snakeCase(value),
    camelCase: () => _.camelCase(value),
  })(() => value)(convertStringTypeType)

export const getNetworkState = (): NetworkState => {
  if (!navigator) return {}

  const { connection: { downlink, effectiveType, type } = {}, onLine } =
    navigator as CustomNavigator
  return { downlink, onLine, effectiveType, type }
}

/**
 * Group options by key
 * @param {Array} options
 * @param {String} groupByKey
 *
 * @return {Object} grouped options
 */
export const getGroupedOptions = (options: Options, groupByKey: string) =>
  _(options)
    .groupBy(groupByKey)
    .map((group, key) => ({
      label: isNil(key) ? 'others' : key,
      options: group,
    }))
    .value()

export const removeFileExtension = (str: string): string => {
  if (!str) return ''
  return str.replace(/\.[^/.]+$/, '')
}

export const isImageFile = (filePath: string): boolean => {
  const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i
  if (!allowedExtensions.exec(filePath)) {
    return false
  }
  return true
}
/**
 * Flatten a multidimensional object
 *
 * For example:
 *   getFlattenedObject({ a: 1, b: { c: 2 } })
 * Returns:
 *   { a: 1, c: 2}
 */
export const getFlattenedObject = (
  obj: Payload,
  options?: { keepPaths?: boolean; paths?: string[]; separator?: string }
): Payload => {
  if (!_.isObject(obj)) return obj

  const { keepPaths = false, paths = [], separator = '/' } = options || {}
  const flattened = {}
  Object.keys(obj).forEach(key => {
    const currentValue = obj[key]
    const newPaths = [...paths, key]
    if (_.isObject(currentValue) && !_.isNil(currentValue)) {
      if (_.isArray(currentValue) && !_.isObject(_.first(currentValue))) {
        flattened[key] = currentValue.join(' ')
      } else {
        const newValue = _.isArray(currentValue)
          ? _.reduce(currentValue, (acc, cur) => ({ ...acc, ...cur }), {})
          : currentValue
        const newOptions = {
          ...options,
          paths: newPaths,
        }
        Object.assign(flattened, getFlattenedObject(newValue, newOptions))
      }
    } else {
      const newKey = keepPaths ? _.compact(newPaths).join(separator) : key
      flattened[newKey] = currentValue
    }
  })
  return flattened
}

export const convertArrayToObject = (
  arr: unknown[],
  identifier: string
): Payload => {
  return _.reduce(
    arr,
    (acc, cur) => {
      return { ...acc, [cur[identifier]]: cur }
    },
    {}
  )
}

export const getNewEntityId = (entityType: string): string =>
  `${entityType}-${uniqueStringId()}`

export const dispatchConcurrentRequests = (
  requests: Array<() => Promise<any>>
): Promise<any[]> => awaityMap(requests, (fn: () => Promise<any>) => fn())

export const padStartZero = (num?: number | string, places?: number): string =>
  _.padStart(num, places, '0')

export const getPropertiesMetadataKeyByName = (
  propertiesMetadata: PropertiesMetadata,
  key = 'name'
): InternalPropertiesMetadata =>
  _.reduce(
    _.keyBy(propertiesMetadata, key),
    (acc, cur, propertyKey) => {
      return {
        ...acc,
        [propertyKey]: { ...cur, terms: _.keyBy(cur.terms, 'key') },
      }
    },
    {}
  )

export const removeNullishProperties = (object: Payload): Payload => {
  if (!_.isObject(object)) return object

  return _.omitBy(object, isNil)
}

export const getRelativePath = (
  absoluteUrl: string,
  baseUrl: string | RegExp
) => {
  return _.replace(absoluteUrl, baseUrl, '')
}

export const getReadableBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`
}

export const CSVArrayToObj = (data: string | any[]) => {
  const objData = []
  for (let i = 1; i < data.length; i++) {
    objData[i - 1] = {}
    for (let k = 0; k < data[0].length && k < data[i].length; k++) {
      const key = data[0][k]
      objData[i - 1][key] = data[i][k]
    }
  }
  return objData
}

export const getBase64EncodedFiles = async (files: File): Promise<Payload> => {
  const promises = _.map(files, file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () =>
        resolve(reader.result.toString().replace(/^data:(.*,)?/, ''))
      reader.onerror = error => reject(error)
    })
  })

  const results = await Promise.all(promises)
  return _.zipObject(_.map(files, 'name'), results)
}

export const getFileNameAndExt = (str: string): [] | [string, string] => {
  const file = str.split('/').pop()
  if (!file) return []

  return [
    file.slice(0, file.lastIndexOf('.')),
    file.slice(file.lastIndexOf('.') + 1, file.length),
  ]
}

export const getNormalizedFilename = (fileName: string) => {
  const [name, ext] = getFileNameAndExt(fileName)
  const newName = name.replace(/[^a-zA-Z0-9_ ]/g, '').replace(/ +/g, '_')
  return `${newName}.${ext}`
}

export const s2ab = (str: string): ArrayBuffer => {
  const buf = new ArrayBuffer(str.length)
  const view = new Uint8Array(buf)
  for (let i = 0; i !== str.length; ++i) view[i] = str.charCodeAt(i) && 0xff
  return buf
}

export const checkDuplicateInArray = (
  array: Array<string | undefined> = []
): boolean => {
  const set = new Set(array)
  return array.length !== set.size
}

export const replaceDynamicProperties = ({
  str,
  placeholder,
  replacementLength = 4,
}: {
  str: string
  placeholder?: string
  replacementLength?: number
}): string => {
  const dynamicPropertiesPatten = /\{properties.*?\}/g
  const replacement =
    placeholder || _.fill(Array(replacementLength), '*').join('')
  return _.replace(str, dynamicPropertiesPatten, replacement)
}

export const shouldAttachImageToPayload = (image: string): boolean => {
  return !(!image || !_.isString(image))
}

export const isEnterKey = (event: KeyboardEvent<HTMLFormElement>): boolean =>
  event.key === 'Enter'

export const isValueEmpty = (value?: string | null | Array<string>): boolean =>
  _.isArray(value) ? _.isEmpty(value) : _.isNil(value) || _.trim(value) === ''

export const getSortOptionsBasedOnColumns = (
  tableColumns: ColumnsWithGroupable
): Option[] =>
  _(tableColumns)
    .reject({ header: '' })
    .map(
      ({ field, header }: { field: string; header: string }): Option => ({
        value: field,
        label: header,
      })
    )
    .value()

export const insertAtIndex = (
  arr1: unknown[],
  arr2: unknown[],
  idx: number,
  key = 'value'
) => {
  const value = _.get(arr1[idx], key)
  const isValueValid = _.isNumber(value) ? true : !!value

  return _.concat(
    _.slice(arr1, 0, idx),
    arr2,
    _.slice(arr1, isValueValid ? idx : idx + 1)
  )
}

export const getValidationNodesError = (errors: string[]) =>
  `${errors.length} item${errors.length > 1 ? 's' : ''} require your attention`

export const isInvalidValue = (value: unknown): boolean => {
  if (_.isNil(value) || _.isUndefined(value)) return true

  if (_.isNumber(value) || _.isBoolean(value)) return false

  if (_.isString(value)) {
    return !_.trim(value)
  }

  if (_.isArray(value) || _.isObject(value)) {
    return _.isEmpty(value)
  }

  return true
}

export const isMobileAndTabletType = (): boolean => {
  const parser = new UAParser()
  const { type } = parser.getDevice()
  return type === 'mobile' || type === 'tablet'
}

/**
 * Truncate a text string to a specified length
 *
 * @param {string} text - The string that needs to be truncated
 * @param {number} limit - The maximum length of the truncated string
 * @returns {string} - The truncated string, or the original string if its length is less than the limit
 * @example truncateText('This is a long string', 10) // returns 'This is a...'
 */
export const truncateText = (text = '', limit = 0): string => {
  if (!text || limit <= 0) return ''
  return text.length > limit ? `${text.slice(0, limit)}...` : text
}

export const getUniqueMergedArray = <T>(...arrays: T[][]): T[] => {
  const combinedArray = ([] as T[]).concat(...arrays)
  const uniqueSet = new Set(combinedArray)
  return Array.from(uniqueSet)
}

export const getStringIfExceedsCount = (value: string, count = 1): string =>
  _.size(value) > count ? value : ''

// TODO: REMOVE THIS
/**
 * 28 Sep 2023: Now as the Inbox Open page was split to the "grouped by site" view and "emissions by site" view,
 * we want to use new names for the user settings, and remove the old ones.
 */
export const omitStaleMethaneEntities = (
  objectWithOptions: Record<string, unknown>
) =>
  _.omit(objectWithOptions, [
    'methane_detections_inbox',
    'methane_detections_inbox_closed',
  ])

export const getPercentage = (value: number | string, fractionDigits = 2) =>
  Math.round(Number(value) * 100).toFixed(fractionDigits)

/** See https://reactrouter.com/en/main/utils/match-routes */
export const getRouteParams = (routePath: string) => {
  const [{ params }] = matchRoutes(
    [
      {
        path: routePath,
      },
    ],
    window.location
  ) || [{}]

  return params
}

/** Just a shortcut for 'window.history.replaceState' */
export const changeRoute = (routeUrl: string, routeValues: Payload<string>) =>
  window.history.replaceState(
    undefined,
    '',
    getRouteUrlWithValues(routeUrl, routeValues)
  )

/**
 * The backend search implemented by 'orFilter' is case-sensitive.
 * As the backend team says, it's not trivial to make it case-insensitive.
 * For now we will use this workaround by providing multiple search string variants
 * to expand search results as much as possible: [vfb, VFB, Vfb]
 * TODO: track the progress of the backend ticket https://sensorup.atlassian.net/browse/OXY-1647 and remove this workaround
 */
export const getSearchTermVariants = (searchTerm?: string) =>
  searchTerm
    ? [searchTerm, _.toUpper(searchTerm), _.capitalize(searchTerm)]
    : []

export const delay = (ms: number): Promise<void> =>
  new Promise(resolve => {
    setTimeout(resolve, ms)
  })
