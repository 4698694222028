// libraries
import { ReactElement, ReactNode } from 'react'

// utils
import { useAssetWidgetConfigs } from 'components/assets/assetsProfile/hooks'

// components
import { TextInput, TitleWithTooltip, Toggle } from 'components/common'

import type { Widget } from 'types/asset'

const BasePanel = ({
  widget,
  children,
}: {
  widget: Widget
  children?: ReactNode
}): ReactElement => {
  const { name, settings } = widget
  const { updateWidgetConfigsSettings, updateAssetWidgetConfigs } =
    useAssetWidgetConfigs(widget)
  const { enableScrollbar = true } = settings || {}
  return (
    <>
      <div className='groupOption'>
        <div className='d-flex justify-content-between align-items-center groupOptionTitle'>
          <TitleWithTooltip title='Widget Title' />
        </div>
        <div className='groupOptionContent'>
          <TextInput
            className='form-control'
            value={name}
            onChange={val => updateAssetWidgetConfigs({ name: val })}
          />
        </div>
      </div>
      <div className='groupOption'>
        <div className='groupOptionTitle d-flex justify-content-between align-items-center'>
          <TitleWithTooltip title='Scrollbars' />
          <Toggle
            label={enableScrollbar ? 'enable' : 'disable'}
            checked={enableScrollbar}
            onToggle={(val: boolean) =>
              updateWidgetConfigsSettings({ enableScrollbar: val })
            }
          />
        </div>
      </div>

      <div className='groupOption'>{children}</div>
    </>
  )
}

export default BasePanel
