import _ from 'lodash'
import moment from 'moment-timezone'
import update from 'immutability-helper'
import { Dispatch, SetStateAction } from 'react'
import Big from 'big.js'

import type { Filters, FilterSpec } from 'types/filter'
import type { Site, SiteEquipmentOption } from 'types/sites'
import type { ActionType, BadgeType, Option, Options } from 'types/common'
import type {
  DetectionData,
  DetectionType,
  EmissionObservationData,
  EmissionRateUnit,
} from 'app/MissionControlMethaneSolution/types/detection'
import type { ColumnsWithGroupable } from 'components/common/DataTable/useDataTableColumns'
import type { UtcISOString } from 'types/datetime'

// utils
import { updateListItem } from 'helpers/utils'

// constants
import {
  DETECTION_SEARCH_FIELDS,
  DETECTION_TYPE_KEY_BY_VALUE,
  EMISSION_RATE_UNITS,
  EMISSION_RATE_UNIT_LABELS,
  NO_SITE_PAGE_NAME,
  SCF_TO_KG_CONVERSION_FACTOR,
  UNKNOWN_DETECTION_TYPE_LABEL,
  DETECTION_DEFAULT_FILTER_KEYS_LIST,
} from 'app/MissionControlMethaneSolution/constants/detection'
import { BADGE_TYPES, OPERATION_OPTIONS } from 'constants/common'

const convertEmissionRateKgToScf = (emissionRate: number | string) =>
  new Big(emissionRate).div(SCF_TO_KG_CONVERSION_FACTOR).toNumber()

export const convertEmissionRateScfToKg = (
  emissionRate: number | string
): number => new Big(emissionRate).times(SCF_TO_KG_CONVERSION_FACTOR).toNumber()

/** Converts emission rate from one unit to another */
export const convertEmissionRate = ({
  nextUnit,
  prevUnit,
  currentValue,
}: {
  nextUnit: string
  prevUnit: string
  currentValue: number | string
}): number => {
  const operation = `${prevUnit}-${nextUnit}`

  switch (operation) {
    case `${EMISSION_RATE_UNITS.kg}-${EMISSION_RATE_UNITS.scf}`:
      return convertEmissionRateKgToScf(currentValue)
    case `${EMISSION_RATE_UNITS.scf}-${EMISSION_RATE_UNITS.kg}`:
      return convertEmissionRateScfToKg(currentValue)
    default:
      throw new Error('Unsupported unit')
  }
}

export const getVolEmissionsRate = (
  emissionsRate?: number | string | null,
  unitLabel: string = EMISSION_RATE_UNIT_LABELS.kg
): string => {
  const validEmissionsRate = _.isNil(emissionsRate)
    ? undefined
    : _.toNumber(emissionsRate)

  const withUnit = _.isNil(unitLabel) ? '' : ` ${unitLabel}`

  // Emission rate should be to 2 decimals
  return _.isNumber(validEmissionsRate)
    ? `${validEmissionsRate.toFixed(2)}${withUnit || ''} `
    : '-'
}

/** Can convert emission rate from kg to scf. Formats the value for display */
export const convertAndFormatEmissionRate = (
  emissionsRate?: number | null,
  // In the DB, emission rate is stored in kilograms by default
  unit: EmissionRateUnit = EMISSION_RATE_UNITS.kg
): string => {
  if (_.isNil(emissionsRate)) {
    return getVolEmissionsRate(emissionsRate)
  }

  switch (unit) {
    case EMISSION_RATE_UNITS.scf: {
      const convertedEmissionsRate = convertEmissionRateKgToScf(emissionsRate)
      return getVolEmissionsRate(
        convertedEmissionsRate,
        EMISSION_RATE_UNIT_LABELS.scf
      )
    }
    // No conversion, just formatting
    case EMISSION_RATE_UNITS.kg:
    default:
      // Since it's kg by default, just passing a value
      return getVolEmissionsRate(emissionsRate)
  }
}

export const transformSitesToOptions = (sites: Site[]): Option[] =>
  sites.map(({ name, id }) => ({
    value: id,
    label: name,
    labelExtras: {
      description: id,
    },
  }))

/** Returns the "orFilter" part of the detections query that can be used to search */
export const getSearchClause = ({
  searchQuery,
  selectedSearchFields,
}: {
  searchQuery?: string
  selectedSearchFields?: string[]
}): Record<string, string[]> | undefined => {
  // Return nothing if the search is not enabled
  if (!searchQuery || !selectedSearchFields) return undefined

  const isSearchFieldsEmpty = _.isEmpty(selectedSearchFields)

  return (
    (
      isSearchFieldsEmpty
        ? // Empty array means that the "All" option is selected.
          // In that case, include all available fields into a clause
          Object.keys(DETECTION_SEARCH_FIELDS)
        : selectedSearchFields
    )
      // The result will be for example: { siteName: ["Some site"] }
      .reduce((acc, fieldName) => ({ ...acc, [fieldName]: [searchQuery] }), {})
  )
}

/** Creating an object { [key]: value } and calling 'getFilterValue' from the specs */
export const prepareFiltersForDetectionsQuery = ({
  filters,
  customFiltersSpecs,
  timezone,
  searchQuery,
  selectedSearchFields,
}: {
  filters?: Filters
  customFiltersSpecs?: FilterSpec[]
  timezone?: string
  searchQuery?: string
  selectedSearchFields?: string[]
}): Filters => {
  const filtersSpecsByKey = _.keyBy(customFiltersSpecs, 'key')
  const orFilter = getSearchClause({ searchQuery, selectedSearchFields })

  const transformedFilters = _.reduce(
    filters,
    (acc, filterValue, key) => {
      const { getFilterValue } = filtersSpecsByKey[key] || {}
      const processedValue = getFilterValue?.(filterValue, { timezone })
      // If 'getFilterValue' returned 'null', that means we can omit this filter
      if (_.isNull(processedValue)) return acc

      const properValue = processedValue || filterValue
      acc[key] = properValue

      return acc
    },
    {} as Filters
  )

  return {
    ...transformedFilters,
    // Putting a search clause if needed
    ...(orFilter && { orFilter }),
  }
}

// this is for backwards compatibility, so that if the detections created before and the detection type is MANUAL (can't be found now from the options), this detection type will be "Other" now
export const getDetectionTypeLabel = (
  detectionType: DetectionType | undefined
): string => {
  if (!detectionType) return UNKNOWN_DETECTION_TYPE_LABEL

  return (
    _.get(DETECTION_TYPE_KEY_BY_VALUE[detectionType], 'label') ||
    UNKNOWN_DETECTION_TYPE_LABEL
  )
}

export const getDetectionLocalList = (
  list: Partial<DetectionData>[],
  item: Partial<DetectionData & { redirect: boolean }>
): Partial<DetectionData>[] => {
  if (item?.redirect) {
    const itemIdsSet = new Set(_.map(list, 'id'))
    return itemIdsSet.has(item.id) ? list : [item, ...list]
  }

  const noDuplicates = _.uniqBy(list, 'id')
  return noDuplicates
}

export const addSiteClickToDetectionsTableColumns = ({
  onClick,
  tableColumns,
}: {
  tableColumns: ColumnsWithGroupable
  onClick: () => void
}): ColumnsWithGroupable => {
  const siteIndex = _.findIndex(tableColumns, { field: 'site' })
  return siteIndex >= 0
    ? update(tableColumns, {
        [siteIndex]: { $merge: { onClick } },
      })
    : tableColumns
}

export const updateDetectionsListFromBlade = ({
  detection,
  actionType,
  selectedDetection,
  setList,
  setSelectedDetection,
  isSet = false,
}: {
  detection: Partial<DetectionData> & { id: string }
  actionType: ActionType
  selectedDetection?: Partial<DetectionData>
  setList: Dispatch<SetStateAction<DetectionData[]>>
  setSelectedDetection: (v: Partial<DetectionData>) => void
  isSet?: boolean
}): void => {
  if (actionType === OPERATION_OPTIONS.delete) {
    setList(oldList => _.reject(oldList, { id: detection.id }))
  } else if (actionType === OPERATION_OPTIONS.update) {
    const newDetection = isSet
      ? { ...selectedDetection, ...detection }
      : _.merge({}, selectedDetection, detection)
    setList(oldList => updateListItem(detection.id, newDetection, oldList))
    if (selectedDetection) {
      setSelectedDetection(newDetection)
    }
  }
}

export const getComponentsOptions = ({
  equipmentId,
  equipmentOptions,
}: {
  equipmentId?: string
  equipmentOptions: SiteEquipmentOption[]
}): Options<string> => {
  if (!equipmentId) return []

  const currentEquipment = _.find(equipmentOptions, { value: equipmentId })
  return _.map(currentEquipment?.components, ({ equipmentComponentName }) => ({
    value: equipmentComponentName as string,
    label: equipmentComponentName as string,
  }))
}

export const getSiteIdForUrl = (detection: EmissionObservationData): string =>
  detection.site?.id ?? NO_SITE_PAGE_NAME

export const isAnyEmissionObservationFilterApplied = (
  filters: Record<string, unknown>
): boolean => !_(filters).omit(DETECTION_DEFAULT_FILTER_KEYS_LIST).isEmpty()

export const calculateVfbStatusBadge = (
  ongoingStatus?: boolean,
  startTime?: string,
  endTime?: string
): {
  content: string
  type: BadgeType
} => {
  if (ongoingStatus || (startTime && !endTime)) {
    return {
      content: 'In progress',
      type: BADGE_TYPES.primary,
    }
  }

  if (startTime && endTime) {
    return {
      content: 'Closed',
      type: BADGE_TYPES.infoGrey,
    }
  }

  return null
}

export const isDetectionRecent = (
  createdTime: UtcISOString,
  thresholdHours: number = 1
): boolean => {
  const createdMoment = moment(createdTime)
  const currentMoment = moment()
  const hoursDiff = currentMoment.diff(createdMoment, 'hours')
  return hoursDiff < thresholdHours
}
