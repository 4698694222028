import { useCallback, useState, ReactElement } from 'react'
import _ from 'lodash'

// type
import type { MediaKey, DataCollectionFormImageView } from 'types/issue'

// components

import { ModalCarousel } from './ModalCarousel'
import Image from './index'

// style
import scss from './index.module.scss'

export type ImageResource = DataCollectionFormImageView

type RenderImagesOptions = {
  remainingImagesCount?: number
  containerClassName?: string
}

const getImageUrl =
  (imageResources: ImageResource[]) =>
  (
    v: MediaKey | { mediaKey: MediaKey; description: string }
  ): { downloadUrl: string; description: string; mediaKey: MediaKey } => {
    return {
      downloadUrl: _.get(
        _.find(imageResources, { mediaKey: _.isString(v) ? v : v.mediaKey }),
        'downloadUrl'
      ),
      ...(_.isString(v) ? { mediaKey: v } : v),
    }
  }

const useImageCarousel = ({
  imageResources = [],
}: {
  imageResources?: ImageResource[]
}): {
  renderCarousel: () => ReactElement
  renderImages: (
    currentImageValues: string[] | string,
    imageWidth?: number,
    imagesPerRow?: number,
    options?: RenderImagesOptions
  ) => ReactElement
} => {
  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)
  const openLightBox = useCallback(
    (mediaKey: MediaKey) => {
      const imageIndex = _.findIndex(imageResources, { mediaKey })

      setCurrentImage(imageIndex)
      setViewerIsOpen(true)
    },
    [imageResources]
  )

  const closeLightBox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  const renderImages = useCallback(
    (
      currentImageValues: [] | string,
      imageWidth = 200,
      imagesPerRow = 2,
      { remainingImagesCount = 0, containerClassName }: RenderImagesOptions = {}
    ) => {
      if (_.isEmpty(currentImageValues)) return <></>

      const getImage = getImageUrl(imageResources)

      const foundMatchedUrls = _.isArray(currentImageValues)
        ? currentImageValues.map(getImage)
        : [getImage(currentImageValues)]

      const displayedImages = _(foundMatchedUrls)
        .compact()
        .filter('downloadUrl')
        .value()

      return (
        <div className='row mt-1 g-2'>
          {displayedImages.map(
            ({ downloadUrl, description, mediaKey }, idx) => {
              const isLastItem = idx === displayedImages.length - 1
              const shouldDisplayRemainingCount =
                isLastItem && remainingImagesCount > 0

              return (
                <div
                  key={mediaKey}
                  className={`${scss.image} col-${12 / imagesPerRow}`}
                  {...(!_.isEmpty(imageResources) && {
                    onClick: () => openLightBox(mediaKey),
                    style: { cursor: 'pointer' },
                  })}
                >
                  <Image
                    src={downloadUrl}
                    style={{
                      width: `${imageWidth}px`,
                      height: `${(imageWidth * 3) / 4}px`,
                    }}
                    title={description}
                    containerClassName={containerClassName}
                    {...(shouldDisplayRemainingCount && {
                      remainingImagesCount,
                    })}
                  />
                </div>
              )
            }
          )}
        </div>
      )
    },
    [imageResources, openLightBox]
  )

  const renderCarousel = useCallback(() => {
    return _.isEmpty(imageResources) ? (
      <></>
    ) : (
      <ModalCarousel
        viewerIsOpen={viewerIsOpen}
        closeLightBox={closeLightBox}
        currentImage={currentImage}
        imageResources={imageResources}
      />
    )
  }, [currentImage, imageResources, viewerIsOpen])

  return { renderCarousel, renderImages }
}

export default useImageCarousel
