// libraries
import {
  MouseEventHandler,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useMemo,
} from 'react'
import _ from 'lodash'
import { useTheme } from '@emotion/react'

// constants
import { AVATAR_SIZES, DEFAULT_AVATAR_URL } from 'constants/user'
import { TOOLTIP_PLACEMENT } from 'constants/settings'

// utils
import { switchcase, toUpperCase, getInitialsInLowerCase } from 'helpers/utils'
import { stringToHslColor } from 'helpers/colour'

// components
import { Tooltip } from 'components/common'
import { HiOutlineUserCircle } from 'components/icons'

// style
import { User } from 'types/user'
import scss from './index.module.scss'

const getAvatarClass = (size: string) => {
  return switchcase({
    [AVATAR_SIZES.xSmall]: scss.avatarCircleXSmall,
    [AVATAR_SIZES.small]: scss.avatarCircleSmall,
    [AVATAR_SIZES.regular]: scss.avatarCircle,
    [AVATAR_SIZES.large]: scss.avatarCircleLarge,
    [AVATAR_SIZES.xLarge]: scss.avatarCircleXLarge,
  })(scss.avatarCircleSmall)(size)
}

type AvatarProps = PropsWithChildren<{
  user: User
  size: keyof typeof AVATAR_SIZES
  tooltip?: boolean
  onClick?: MouseEventHandler<HTMLDivElement>
  onHover?: MouseEventHandler<HTMLDivElement>
  className?: string
  useDefaultAvatar?: boolean
  overlay?: string | ReactNode
  hover?: boolean
  hoverIcon?: string
}>

const Avatar = ({
  user,
  size,
  tooltip,
  onClick,
  className,
  useDefaultAvatar,
  children,
  overlay,
  onHover,
  hover,
  hoverIcon,
}: AvatarProps): ReactElement => {
  const { primary } = useTheme()

  const { username, familyName, givenName, name, avatarUrl, initials } = user

  const avatarSizeClassName = useMemo(() => getAvatarClass(size), [size])

  const userDisplayName = useMemo(
    () =>
      name ||
      (familyName && givenName
        ? `${_.capitalize(givenName)} ${_.capitalize(familyName)}`
        : username),
    [name, familyName, givenName, username]
  )

  const nameColour = useMemo(() => {
    return stringToHslColor(userDisplayName, 40, 45)
  }, [userDisplayName])

  const validAvatarUrl = useMemo(() => {
    return avatarUrl && (avatarUrl !== DEFAULT_AVATAR_URL || useDefaultAvatar)
      ? avatarUrl
      : ''
  }, [avatarUrl, useDefaultAvatar])

  const bgStyle = useMemo(
    () =>
      validAvatarUrl
        ? {
            backgroundImage: `url("${validAvatarUrl}")`,
            backgroundSize: 'cover',
          }
        : { backgroundColor: nameColour },
    [validAvatarUrl, nameColour]
  )

  const userAvatar = useMemo(() => {
    const nameInitials = initials || getInitialsInLowerCase(userDisplayName)

    const avatar = (
      <div
        {...(onHover
          ? {
              onMouseEnter: () => onHover(true),
              onMouseLeave: () => onHover(false),
            }
          : {})}
        className={`${avatarSizeClassName} ${
          className || ''
        } d-flex align-items-center`}
        style={hover ? { backgroundColor: nameColour } : bgStyle}
        onClick={onClick}
        data-toggle='tooltip'
        data-user-id={username}
      >
        {hover ? (
          <span className='w-100'>
            <span className={scss.avatarHoverIcon}>{hoverIcon}</span>
          </span>
        ) : (
          <>
            {!validAvatarUrl && (
              <span className='w-100'>
                <span className={scss.initials}>
                  {toUpperCase(nameInitials)}
                </span>
              </span>
            )}
            {children}
          </>
        )}
      </div>
    )

    return nameInitials ? (
      <div className='position-relative'>
        {tooltip ? (
          <Tooltip
            placement={TOOLTIP_PLACEMENT.top}
            trigger={['hover']}
            overlay={<span>{userDisplayName}</span>}
          >
            {avatar}
          </Tooltip>
        ) : (
          avatar
        )}
        {overlay}
      </div>
    ) : (
      <HiOutlineUserCircle
        className={`${avatarSizeClassName} ${scss.iconAvatar}`}
        color={primary}
      />
    )
  }, [
    initials,
    userDisplayName,
    onHover,
    avatarSizeClassName,
    className,
    hover,
    nameColour,
    bgStyle,
    onClick,
    username,
    hoverIcon,
    validAvatarUrl,
    children,
    tooltip,
    overlay,
    primary,
  ])

  return userAvatar || <></>
}

export const SmallAvatar = (props: AvatarProps): ReactElement => (
  <Avatar {...props} size={AVATAR_SIZES.small} />
)

export const LargeAvatar = (props: AvatarProps): ReactElement => (
  <Avatar {...props} size={AVATAR_SIZES.large} />
)

Avatar.defaultProps = {
  size: AVATAR_SIZES.regular,
  tooltip: true,
  onClick: undefined,
  className: undefined,
  children: null,
  overlay: undefined,
  useDefaultAvatar: false,
  onHover: undefined,
  hover: undefined,
  hoverIcon: undefined,
}

export default Avatar
