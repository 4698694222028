import { ReactElement } from 'react'
import { Field, useField } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners'
import _ from 'lodash'

// constants
import { FORM_BUILDER_IMAGE_CONFIGS } from 'constants/formBuilder'

// utils
import { getFieldName } from 'helpers/formBuilder'

// components
import * as FormComponents from 'components/common/Form'

const MaxImagesInput = ({
  nodeId,
  ...rest
}: {
  nodeId: string
}): ReactElement => {
  const isMultiInput = useField(getFieldName(nodeId, 'isMulti')).input
  const isMultiValue = isMultiInput.value

  const maxImagesFieldName = getFieldName(nodeId, 'maxItems')
  const maxImagesFieldInput = useField(maxImagesFieldName).input

  return (
    <>
      <OnChange name={getFieldName(nodeId, 'isMulti')}>
        {() => {
          if (isMultiValue) {
            if (_.isEmpty(maxImagesFieldInput.value)) {
              maxImagesFieldInput.onChange(FORM_BUILDER_IMAGE_CONFIGS.default)
            }
          } else {
            maxImagesFieldInput.onChange(FORM_BUILDER_IMAGE_CONFIGS.min)
          }
        }}
      </OnChange>
      <Field
        {...rest}
        {...(!isMultiValue && { label: undefined })}
        component={FormComponents.Input}
        type={isMultiValue ? 'number' : 'hidden'}
        min={FORM_BUILDER_IMAGE_CONFIGS.min}
        max={FORM_BUILDER_IMAGE_CONFIGS.max}
        step={FORM_BUILDER_IMAGE_CONFIGS.step}
        defaultValue={FORM_BUILDER_IMAGE_CONFIGS.default}
      />
    </>
  )
}

export default MaxImagesInput
