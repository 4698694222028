import unipipeSelectors from './unipipe'
import ancillaryDataSelectors from './ancillaryData'
import workflowSelectors from './workflow'
import userSelectors from './user'
import siteSelectors from './site'

export const useSelectors = state => {
  return {
    unipipeSelectors: unipipeSelectors(state),
    ancillaryDataSelectors: ancillaryDataSelectors(state),
    workflowSelectors: workflowSelectors(state),
    userSelectors: userSelectors(state),
    siteSelectors: siteSelectors(state),
  }
}
