import { unipipeState } from '../reducers/unipipe'
import { workflowState } from '../reducers/workflow'
import { userState } from '../reducers/user'
import { ancillaryDataState } from '../reducers/ancillaryData'
import { mapState } from '../reducers/map'
import { siteState } from '../reducers/site'

export const initialState = {
  unipipeState,
  workflowState,
  userState,
  ancillaryDataState,
  siteState,
  mapState,
}
