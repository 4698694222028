// libraries
import _ from 'lodash'
import { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import isEqual from 'fast-deep-equal'
import { useGetSet, useMountedState, useUpdateEffect } from 'react-use'
import { useRequest } from 'ahooks'

// constants
import { FILTER_CONDITIONS } from 'constants/filter'
import { GALLERY_LIST_FILTER_TYPES } from 'constants/common'
import { LOAD_MORE_ITEMS_PER_PAGE } from 'app/MissionControlMethaneSolution/constants/common'

// utils
import {
  fetchListData,
  updateList,
  objectToBase64,
  fetchGraphQLListData,
} from 'helpers/utils'
import { reportErrors } from 'helpers/log'

import type { Filters } from 'types/filter'
import type { PageInfo } from 'types/graphql'
import type {
  ActionType,
  AsyncState,
  Payload,
  SortByOptions,
} from 'types/common'
import type { RelayStyleData, ListRelayStyleData } from 'services/api/utils'
import type { QueryParams } from 'types/services'
import type { OnListItemChange } from './useListItemActions'

const encodeFilters = (filters: Filters, condition: string) =>
  objectToBase64(
    condition === FILTER_CONDITIONS.or ? { $or: filters } : filters
  )

export const getListFiltersParams = (
  filters: Filters,
  condition: string,
  isGraphql = false
): Payload => {
  const validFilters = _.omitBy(filters, filter =>
    _.isArray(filter) ? _.isEmpty(filter) : _.isNil(filter)
  )

  return _.isEmpty(validFilters)
    ? {}
    : {
        filter: isGraphql
          ? validFilters
          : encodeFilters(validFilters, condition),
      }
}

// https://ahooks.js.org/hooks/use-request/basic#options
const DEFAULT_USE_REQUEST_OPTIONS = {
  manual: true,
}

export type UseFetchListProps<T> = Partial<{
  key?: string
  initialList: T[]
  listFn: (payload: ListRelayStyleData<T>) => Promise<RelayStyleData<T>>
  updateListFn: (list: T[]) => void
  shouldFetchOnMount: boolean
  filters: Filters
  isGraphql: boolean
  first: number
  filtersCondition: string
  delayInSeconds: number
  listFnParams?: QueryParams
  sortBy?: SortByOptions
  queryPayload?: Record<string, unknown>
}>

export type UseFetchListState<T = unknown> = {
  list: T[]
  pageInfo: PageInfo
  listState: AsyncState<T[]> & { isFirstRequestDone?: boolean }
  abortController?: AbortController
  onChange: OnListItemChange<T>
  setList: React.Dispatch<React.SetStateAction<T[]>>
  fetchMoreListData: () => Promise<void>
  fetchAllListData: () => Promise<void>
  fetchList: (enableLoadMore?: boolean) => Promise<T[]>
  isLoadingMore: boolean
}

const useFetchList = <T>({
  key,
  listFn,
  updateListFn = _.noop,
  initialList,
  filters,
  filtersCondition = FILTER_CONDITIONS.and,
  isGraphql = true,
  delayInSeconds = 0,
  first,
  listFnParams,
  sortBy,
  queryPayload, // anything else you want to pass into a query
}: UseFetchListProps<T>): UseFetchListState<T> => {
  const isMounted = useMountedState()
  const isFirstRequestDoneRef = useRef(false)

  const queryParams = useMemo(() => {
    if (!filters) return undefined

    const backendFilters = _.omit(filters, [
      GALLERY_LIST_FILTER_TYPES.isFavorite,
    ])
    return _.omitBy(
      {
        ...getListFiltersParams(backendFilters, filtersCondition, isGraphql),
        sortBy,
      },
      _.isNil
    )
  }, [filters, filtersCondition, isGraphql, sortBy])

  const [list, setList] = useState(() => initialList ?? [])
  const [getPageInfo, setPageInfo] = useGetSet<PageInfo>({} as PageInfo)
  const [controller, setAbortController] = useState<AbortController>()
  const [isLoadingMore, setIsLoadingMore] = useState(false)

  const {
    // https://ahooks.js.org/hooks/use-request/basic#result
    runAsync: fetchList,
    loading,
    error,
    data,
  } = useRequest(
    async (enableLoadMore = false, loadMoreFirst?: number): Promise<T[]> => {
      if (!_.isFunction(listFn)) {
        reportErrors('listFn is invalid', { listFn })
        return []
      }

      if (controller) {
        controller.abort()
        setAbortController(undefined)
      }

      if (!enableLoadMore) {
        setPageInfo({} as PageInfo)
        if (delayInSeconds) {
          await new Promise(resolve => {
            setTimeout(resolve, delayInSeconds * 1000)
          })
        }
      }

      const abortController = new AbortController()
      setAbortController(abortController)

      const pageInfo = getPageInfo()
      const result = isGraphql
        ? await fetchGraphQLListData({
            ...listFnParams,
            fetchFunc: listFn,
            queryParams: {
              ...queryParams,
              ...(first && { first }),
              ...(enableLoadMore && {
                ...(pageInfo.endCursor && { after: pageInfo.endCursor }),
                first: loadMoreFirst || first,
              }),
              ...queryPayload,
            },
            abortController,
          })
        : await fetchListData(listFn, { ...queryParams, ...listFnParams })

      if (isMounted()) {
        if (isGraphql) {
          const { data: resultData } = result

          setPageInfo(result.pageInfo)

          setList(oldList =>
            enableLoadMore ? [...oldList, ...resultData] : resultData
          )
        } else {
          setList(result)
        }
      }

      isFirstRequestDoneRef.current = true

      return result
    },
    DEFAULT_USE_REQUEST_OPTIONS
  )

  const onChange = useCallback(
    (type: ActionType, payload?: Partial<T>) => {
      const newList: T[] = updateList(list, type, payload, {
        insertOnTop: true,
      })
      setList(newList)
    },
    [list]
  )

  useEffect(() => {
    fetchList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams, queryPayload, key])

  useUpdateEffect(() => {
    if (!isEqual(initialList, list)) {
      updateListFn(list)
    }
  }, [list])

  useUpdateEffect(() => {
    if (!initialList) return

    setList(oldList => (isEqual(initialList, oldList) ? oldList : initialList))
  }, [initialList])

  const fetchMoreListData = useCallback(
    async (loadMoreFirst?: number) => {
      setIsLoadingMore(true)
      await fetchList(true, loadMoreFirst)
    },
    [fetchList]
  )

  const fetchAllListData = useCallback(
    async (loadMoreFirst = LOAD_MORE_ITEMS_PER_PAGE) => {
      let loadMore = getPageInfo().hasNextPage
      while (loadMore) {
        await fetchMoreListData(loadMoreFirst)
        loadMore = getPageInfo().hasNextPage
      }
    },
    [fetchMoreListData, getPageInfo]
  )

  useEffect(() => {
    if (!loading) {
      setIsLoadingMore(false)
    }
  }, [loading])

  return {
    list,
    setList,
    onChange,
    fetchList,
    fetchMoreListData,
    fetchAllListData,
    isLoadingMore,
    pageInfo: getPageInfo() || {},
    abortController: controller,
    listState: {
      loading,
      error,
      value: data,
      isFirstRequestDone: isFirstRequestDoneRef.current,
    },
  }
}

export default useFetchList
