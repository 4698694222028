import { render } from 'react-dom'
import SUExplorer from 'SUExplorer'
import * as serviceWorker from 'serviceWorker'
import 'style/global.scss'
import './index.css'

render(<SUExplorer />, document.getElementById('root'))
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
