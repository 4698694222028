// libraries
import { ReactElement } from 'react'
import _ from 'lodash'

// constants
import { TOOLTIP_PLACEMENT } from 'constants/settings'

// components
import { Tooltip } from 'components/common'

import type { ColumnBodyOptions } from 'primereact/column'
import type { IssueGeojson } from 'types/issue'

const TextTemplate = (
  rowData: IssueGeojson,
  { field }: ColumnBodyOptions
): ReactElement => {
  const description = _.get(rowData, field)

  return (
    <Tooltip
      placement={TOOLTIP_PLACEMENT.top}
      trigger={['hover']}
      overlay={<span>{description}</span>}
    >
      <div className='text-truncate'>{description}</div>
    </Tooltip>
  )
}

export default TextTemplate
