// libraries
import { useMemo, ReactElement, useCallback } from 'react'
import { useUpdateEffect } from 'react-use'
import _ from 'lodash'
import * as Sentry from '@sentry/react'

// constants
import { THEMES } from 'constants/colour'
import { NO_DATA_PLACEHOLDER } from 'constants/common'

// utils
import { useFetchAsset } from 'components/assets/assetsProfile/hooks'
import { useTimezone } from 'hooks'

// components
import { Loading } from 'components/common'
import NoAvailableWidget from 'components/widget/common/NoAvailableWidget'

import type { AsyncState } from 'types/common'
import type { Timezone } from 'types/datetime'
import type { AssetBaseWidget, Asset } from 'types/asset'

import scss from './index.module.scss'

const { ErrorBoundary } = Sentry

const FallbackComponent = (): ReactElement => {
  return <NoAvailableWidget content='Something went wrong' />
}

const BaseWidget = ({
  loading,
  value,
  error,
  className,
  children,
}: AsyncState<Asset> & {
  className?: string
  children: ReactElement
}): ReactElement => {
  const renderWidget = () => {
    if (loading) return <Loading />
    if (error) return <NoAvailableWidget />
    return _.isEmpty(value) || _.isNil(value) ? (
      <NoAvailableWidget content={NO_DATA_PLACEHOLDER} />
    ) : (
      <div className={`${className || scss.content}`}>{children}</div>
    )
  }

  return (
    <ErrorBoundary fallback={FallbackComponent}>{renderWidget()}</ErrorBoundary>
  )
}

type BaseWidgetWithAssetStateProps = AssetBaseWidget & {
  className?: string
  withIssues?: boolean
  issuePickFields?: string[]
  fetchOnMount?: boolean
  forceScroll?: boolean
  isPrintable?: boolean
  render: ({
    value,
    timezone,
  }: {
    value: Asset
    timezone?: Timezone
  }) => ReactElement
}

export const BaseWidgetWithAssetState = ({
  assetId,
  settings,
  render,
  className = scss.center,
  withIssues = false,
  issuePickFields = [],
  fetchOnMount = true,
  theme = THEMES.dark,
  name,
  onWidgetLoad,
  forceScroll = false,
  isPrintable = false,
}: BaseWidgetWithAssetStateProps): ReactElement => {
  const { timezone } = useTimezone()

  const {
    enableScrollbar = true,
    relatedAssetRelationshipId,
    relatedAssetProfileId,
    ...nonAssetDataChangeRelatedSettings
  } = settings

  const onAssetLoaded = useCallback(() => {
    if (onWidgetLoad) {
      onWidgetLoad()
    }
  }, [onWidgetLoad])

  const { assetState, fetchAsset } = useFetchAsset({
    assetId,
    fetchOnMount,
    withIssues,
    issuePickFields,
    settings,
    onAssetLoaded,
  })

  const { value } = assetState

  useUpdateEffect(() => {
    fetchAsset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetId, relatedAssetRelationshipId, relatedAssetProfileId])

  const widgetContent = useMemo(() => {
    if (!value) return <></>

    return render({ value: _.omitBy(value, _.isNil) as Asset, timezone })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, render, timezone, nonAssetDataChangeRelatedSettings])

  const isLightTheme = useMemo(() => theme === THEMES.light, [theme])

  return (
    <>
      {name && (
        <div
          className={`${scss.header} d-flex justify-content-between`}
          style={{ color: isLightTheme ? 'black' : 'white' }}
        >
          <h5 className={scss.name}>{name}</h5>
        </div>
      )}
      <div
        className={`position-relative ${
          !enableScrollbar || (isPrintable && !forceScroll)
            ? 'overflow-hidden'
            : scss.scroll
        } ${!isPrintable ? 'flex-grow-1' : ''}`}
      >
        <BaseWidget {...assetState} className={className}>
          {widgetContent}
        </BaseWidget>
      </div>
    </>
  )
}

export default BaseWidget
