import to from 'await-to-js'
import queryString from 'query-string'
import _ from 'lodash'

// utils
import log, { reportException } from 'helpers/log'
import { getFetchOptions, getNetworkState } from 'helpers/utils'

import type { Payload } from 'types/common'

type ApiErrorConstructor = new ({
  error,
  method,
  url,
}: {
  error: Error | string
  method: string
  url: string
}) => Error

const handleApiFetchError = async ({
  url,
  err,
  method,
  response,
  ApiError,
}: {
  url: string
  err: Error | null
  method: string
  response: Response | undefined
  ApiError: ApiErrorConstructor | ErrorConstructor
}) => {
  const { status, statusText, ok } = response || {}
  if (!err && ok) return undefined

  let errorDetails
  if (err) {
    errorDetails = err.toString()
    const { onLine } = getNetworkState()
    if (!onLine) {
      errorDetails += '. The Internet connection appears to be offline.'
    }
  } else if (response && !ok) {
    const [jsonParseError, result] = await to(response.json())
    if (jsonParseError) {
      log.debug('Non-JSON error response')
      errorDetails = `${status}.${statusText}`
    } else {
      const { errors, error } = result || {}
      errorDetails = errors ? _.first(errors) : error
    }
  }

  const apiError = new ApiError({ error: errorDetails, method, url })
  reportException(apiError, {
    ok,
    status,
    statusText,
    errorDetails: _.isObject(errorDetails)
      ? JSON.stringify(errorDetails)
      : errorDetails,
  })
  return apiError
}

export const apiFetch =
  (method: string, ApiError: new (message: string) => Error = Error) =>
  async <T>(
    url: string,
    payload: Payload,
    params: Payload = {}
  ): Promise<T | undefined> => {
    const config = getFetchOptions({ method, payload, params })
    const [err, response] = await to(fetch(url, config))
    const apiError = await handleApiFetchError({
      url,
      err,
      method,
      response,
      ApiError,
    })
    if (apiError) {
      throw apiError
    }
    if (!response) return undefined

    if (
      response.status === 204 ||
      (response.status === 200 && method === 'DELETE')
    ) {
      return undefined
    }

    const [jsonParseError, json] = await to(response.json())
    if (jsonParseError) {
      log.warn('JSON parse error', response)
    }
    // Ignore JSON parsing error some calls don't return JSON
    return jsonParseError ? undefined : json
  }

const destroy = apiFetch('DELETE')
const get = apiFetch('GET')
const patch = apiFetch('PATCH')
const post = apiFetch('POST')
const put = apiFetch('PUT')

export const methods = {
  destroy,
  get,
  patch,
  post,
  put,
}

class ApiService {
  ready = false

  baseUrl = ''

  configure(config: { baseUrl: string }) {
    const { baseUrl } = config

    this.baseUrl = baseUrl

    this.ready = true
  }

  checkReady() {
    if (!this.ready) {
      throw new Error('ApiService not configured for use')
    }
  }

  getUrl = (urlSuffix: string, queryParams: Payload) => {
    const queryParamsString = _.isEmpty(queryParams)
      ? ''
      : `/?${queryString.stringify(queryParams)}`

    return `${this.baseUrl}/${urlSuffix}${queryParamsString}`
  }

  async getList(urlSuffix: string, queryParams: Payload, ...rest) {
    this.checkReady()
    const url = this.getUrl(urlSuffix, queryParams)
    const response = await get(url, ...rest)
    return response
  }

  async get(urlSuffix: string, queryParams: Payload, ...rest) {
    const response = await this.getList(urlSuffix, queryParams, ...rest)
    return response.data
  }

  async patch(urlSuffix: string, queryParams: Payload, ...rest) {
    this.checkReady()
    const url = this.getUrl(urlSuffix, queryParams)
    const response = await patch(url, ...rest)
    return response.data
  }

  async post(urlSuffix: string, ...rest) {
    this.checkReady()
    const url = `${this.baseUrl}/${urlSuffix}`
    const response = await post(url, ...rest)
    return response.data
  }

  async put(urlSuffix: string, ...rest) {
    this.checkReady()
    const url = `${this.baseUrl}/${urlSuffix}`
    const response = await put(url, ...rest)
    return response.data
  }

  destroy(urlSuffix: string, queryParams: Payload, ...rest) {
    this.checkReady()

    const url = this.getUrl(urlSuffix, queryParams)
    return destroy(url, ...rest)
  }
}

const instance = new ApiService()

export default instance
