import { initialState } from '../state/initialStates'

import unipipeActions from './unipipe'
import workflowActions from './workflow'
import userActions from './user'
import ancillaryDataActions from './ancillaryData'
import mapActions from './map'
import siteActions from './site'

export const RESET_LOCAL_STATE = 'RESET_LOCAL_STATE'

export const useActions = (
  state: typeof initialState,
  dispatch: React.Dispatch<{ type?: string; exclude?: string[] }>
): {
  resetLocalState: (exclude: string[]) => void
  unipipeActions: ReturnType<typeof unipipeActions>
  workflowActions: ReturnType<typeof workflowActions>
  userActions: ReturnType<typeof userActions>
  ancillaryDataActions: ReturnType<typeof ancillaryDataActions>
  mapActions: ReturnType<typeof mapActions>
  siteActions: ReturnType<typeof siteActions>
} => {
  return {
    unipipeActions: unipipeActions({ state: state.unipipeState, dispatch }),
    workflowActions: workflowActions({ state: state.workflowState, dispatch }),
    userActions: userActions({ state: state.userState, dispatch }),
    ancillaryDataActions: ancillaryDataActions({
      state: state.ancillaryDataState,
      dispatch,
    }),
    siteActions: siteActions({ dispatch }),
    mapActions: mapActions({ state: state.mapState, dispatch }),
    resetLocalState: (exclude: string[]) =>
      dispatch({
        type: RESET_LOCAL_STATE,
        exclude,
      }),
  }
}
